import { default as overviewc1xj66I7ByMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/_/banner/overview.vue?macro=true";
import { default as indexLVUUuMbfDXMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/[...slug]/index.vue?macro=true";
import { default as thank_45youmFYd96OGhhMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/[...slug]/thank-you.vue?macro=true";
import { default as index9TNDTf5EP5Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/404/index.vue?macro=true";
import { default as indexbinaNHJHopMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/changePassword/index.vue?macro=true";
import { default as successxncYtBylRPMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/changePassword/success.vue?macro=true";
import { default as indexOKtZLqRUYIMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/index.vue?macro=true";
import { default as indexm5Ihg0yhWMMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/orders/index.vue?macro=true";
import { default as indexctvctuMfrjMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/profile/index.vue?macro=true";
import { default as index9mwaQWuZXAMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/resetPassword/index.vue?macro=true";
import { default as successPYQpn2U420Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/resetPassword/success.vue?macro=true";
import { default as itemsAO3xt2FTe3Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/items.vue?macro=true";
import { default as reviewksVtklIBnAMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/review.vue?macro=true";
import { default as shippingwt8ShkDLm1Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/shipping.vue?macro=true";
import { default as form65X6XGcv4GMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/complaint/form.vue?macro=true";
import { default as indexEfZoSMtp8lMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/index.vue?macro=true";
import { default as addressBMGYdPyvExMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/address.vue?macro=true";
import { default as basket8dDG7bvDGPMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/basket.vue?macro=true";
import { default as loginmCbiwu2UMKMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/login.vue?macro=true";
import { default as payment_45shipping0zq9O1Sr6MMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/payment-shipping.vue?macro=true";
import { default as reviewzmysQPdBTXMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/review.vue?macro=true";
import { default as theme_45testzka5xSUxHAMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/debug/theme-test.vue?macro=true";
import { default as toolwf09nIEBqWMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/debug/tool.vue?macro=true";
import { default as indexzesY8QrvVsMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/login/index.vue?macro=true";
import { default as successTIJpI9beVhMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/login/success.vue?macro=true";
import { default as _91slug_93g2K4FeHrg8Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/preview/banner/[slug].vue?macro=true";
import { default as indexm3GxtqUNkMMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/auto-delivery/index.vue?macro=true";
import { default as _91id_93doKGxtHXu9Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/favorites/[...slug]/[id].vue?macro=true";
import { default as index716hBx4BlnMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/favorites/index.vue?macro=true";
import { default as indexubYHqZB4TgMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/index.vue?macro=true";
import { default as indexIPBiP67HfyMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/order-alarm/index.vue?macro=true";
import { default as indexxOSVtwiqQ5Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/ordered-products/index.vue?macro=true";
import { default as indexFS3mBU2aAnMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/viewed-products/index.vue?macro=true";
import { default as indexsU3FSiLt1OMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/register/index.vue?macro=true";
import { default as successY4rzAFJGECMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/register/success.vue?macro=true";
import { default as checkoutU3ngDoQeACMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/repairshop/checkout.vue?macro=true";
import { default as index6o7h0b6h2xMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/sales/[productId]/index.vue?macro=true";
import { default as indexNdW7ClDT4LMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/[...slug]/index.vue?macro=true";
import { default as indexDdvrasJ11OMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/manufacturer/[...slug]/index.vue?macro=true";
import { default as _91id_93ESuobqRFiwMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/product/[...slug]/[id].vue?macro=true";
import { default as indexOjpei7QxtcMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/search/[...slug]/index.vue?macro=true";
import { default as overviewAsqzyEZn5ZMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/_/banner/overview.vue?macro=true";
import { default as index1ozAKkrdaeMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/[...slug]/index.vue?macro=true";
import { default as thank_45youHOxaLCpcxPMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/[...slug]/thank-you.vue?macro=true";
import { default as indexxxpelmL8l4Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/404/index.vue?macro=true";
import { default as indexFhwoUCFbGuMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/changePassword/index.vue?macro=true";
import { default as successvE16D59m1YMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/changePassword/success.vue?macro=true";
import { default as indexcjAh9gop6eMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/index.vue?macro=true";
import { default as indexPDdiQIauA0Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/orders/index.vue?macro=true";
import { default as indexEieqmBKy3uMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/profile/index.vue?macro=true";
import { default as indexhpY3B3Vnd5Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/resetPassword/index.vue?macro=true";
import { default as successIaW4ZhmhYlMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/resetPassword/success.vue?macro=true";
import { default as itemsUyjyPt4NFOMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/items.vue?macro=true";
import { default as reviewheBh9Cr9HNMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/review.vue?macro=true";
import { default as shippingUiXg2kpUm6Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/shipping.vue?macro=true";
import { default as formE3T19pJT0tMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/complaint/form.vue?macro=true";
import { default as indexQLpR1SkXrbMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/index.vue?macro=true";
import { default as addressxXtYUUsyrFMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/address.vue?macro=true";
import { default as basketKCoqvpWcnMMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/basket.vue?macro=true";
import { default as loginmJtOHFQoWhMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/login.vue?macro=true";
import { default as payment_45shipping8N0RqU7HoGMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/payment-shipping.vue?macro=true";
import { default as reviewHHhlExyKELMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/review.vue?macro=true";
import { default as theme_45testEFsRRySBkYMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/debug/theme-test.vue?macro=true";
import { default as tool3zxr8vsFp8Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/debug/tool.vue?macro=true";
import { default as indexi6eOCaDFSXMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/login/index.vue?macro=true";
import { default as successLA4eDiasBaMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/login/success.vue?macro=true";
import { default as _91slug_93D6qR8t08XvMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/preview/banner/[slug].vue?macro=true";
import { default as index1qujTxPysJMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/auto-delivery/index.vue?macro=true";
import { default as _91id_93yIjhBluM7fMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/favorites/[...slug]/[id].vue?macro=true";
import { default as indexvuyMHXIqMGMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/favorites/index.vue?macro=true";
import { default as indexzc2pzVJcDQMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/index.vue?macro=true";
import { default as indexBWMR4UtkyDMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/order-alarm/index.vue?macro=true";
import { default as index8wBuIUbBGGMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/ordered-products/index.vue?macro=true";
import { default as index5ZvWiFwnmIMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/viewed-products/index.vue?macro=true";
import { default as index9C1BR7ag0MMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/register/index.vue?macro=true";
import { default as successD4vHILRR9vMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/register/success.vue?macro=true";
import { default as checkoutPjEsrXvMAfMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/repairshop/checkout.vue?macro=true";
import { default as indexkFY0baP8tOMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/sales/[productId]/index.vue?macro=true";
import { default as indexaUv5nYhlDXMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/[...slug]/index.vue?macro=true";
import { default as index6aixs50fOZMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/manufacturer/[...slug]/index.vue?macro=true";
import { default as _91id_93MNvCWplEX5Meta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/product/[...slug]/[id].vue?macro=true";
import { default as indexr3g6CYwSIkMeta } from "/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/search/[...slug]/index.vue?macro=true";
export default [
  {
    name: overviewc1xj66I7ByMeta?.name ?? "minilu-_-banner-overview",
    path: overviewc1xj66I7ByMeta?.path ?? "/minilu/_/banner/overview",
    meta: overviewc1xj66I7ByMeta || {},
    alias: overviewc1xj66I7ByMeta?.alias || [],
    redirect: overviewc1xj66I7ByMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/_/banner/overview.vue").then(m => m.default || m)
  },
  {
    name: indexLVUUuMbfDXMeta?.name ?? "minilu-slug",
    path: indexLVUUuMbfDXMeta?.path ?? "/minilu/:slug(.*)*",
    meta: indexLVUUuMbfDXMeta || {},
    alias: indexLVUUuMbfDXMeta?.alias || [],
    redirect: indexLVUUuMbfDXMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: thank_45youmFYd96OGhhMeta?.name ?? "minilu-slug-thank-you",
    path: thank_45youmFYd96OGhhMeta?.path ?? "/minilu/:slug(.*)*/thank-you",
    meta: thank_45youmFYd96OGhhMeta || {},
    alias: thank_45youmFYd96OGhhMeta?.alias || [],
    redirect: thank_45youmFYd96OGhhMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/[...slug]/thank-you.vue").then(m => m.default || m)
  },
  {
    name: index9TNDTf5EP5Meta?.name ?? "minilu-404",
    path: index9TNDTf5EP5Meta?.path ?? "/minilu/404",
    meta: index9TNDTf5EP5Meta || {},
    alias: index9TNDTf5EP5Meta?.alias || [],
    redirect: index9TNDTf5EP5Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/404/index.vue").then(m => m.default || m)
  },
  {
    name: indexbinaNHJHopMeta?.name ?? "minilu-account-changePassword",
    path: indexbinaNHJHopMeta?.path ?? "/minilu/account/changePassword",
    meta: indexbinaNHJHopMeta || {},
    alias: indexbinaNHJHopMeta?.alias || [],
    redirect: indexbinaNHJHopMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/changePassword/index.vue").then(m => m.default || m)
  },
  {
    name: successxncYtBylRPMeta?.name ?? "minilu-account-changePassword-success",
    path: successxncYtBylRPMeta?.path ?? "/minilu/account/changePassword/success",
    meta: successxncYtBylRPMeta || {},
    alias: successxncYtBylRPMeta?.alias || [],
    redirect: successxncYtBylRPMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/changePassword/success.vue").then(m => m.default || m)
  },
  {
    name: indexOKtZLqRUYIMeta?.name ?? "minilu-account",
    path: indexOKtZLqRUYIMeta?.path ?? "/minilu/account",
    meta: indexOKtZLqRUYIMeta || {},
    alias: indexOKtZLqRUYIMeta?.alias || [],
    redirect: indexOKtZLqRUYIMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexm5Ihg0yhWMMeta?.name ?? "minilu-account-orders",
    path: indexm5Ihg0yhWMMeta?.path ?? "/minilu/account/orders",
    meta: indexm5Ihg0yhWMMeta || {},
    alias: indexm5Ihg0yhWMMeta?.alias || [],
    redirect: indexm5Ihg0yhWMMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexctvctuMfrjMeta?.name ?? "minilu-account-profile",
    path: indexctvctuMfrjMeta?.path ?? "/minilu/account/profile",
    meta: indexctvctuMfrjMeta || {},
    alias: indexctvctuMfrjMeta?.alias || [],
    redirect: indexctvctuMfrjMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: index9mwaQWuZXAMeta?.name ?? "minilu-account-resetPassword",
    path: index9mwaQWuZXAMeta?.path ?? "/minilu/account/resetPassword",
    meta: index9mwaQWuZXAMeta || {},
    alias: index9mwaQWuZXAMeta?.alias || [],
    redirect: index9mwaQWuZXAMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/resetPassword/index.vue").then(m => m.default || m)
  },
  {
    name: successPYQpn2U420Meta?.name ?? "minilu-account-resetPassword-success",
    path: successPYQpn2U420Meta?.path ?? "/minilu/account/resetPassword/success",
    meta: successPYQpn2U420Meta || {},
    alias: successPYQpn2U420Meta?.alias || [],
    redirect: successPYQpn2U420Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/resetPassword/success.vue").then(m => m.default || m)
  },
  {
    name: itemsAO3xt2FTe3Meta?.name ?? "minilu-account-returns-checkout-items",
    path: itemsAO3xt2FTe3Meta?.path ?? "/minilu/account/returns/checkout/items",
    meta: itemsAO3xt2FTe3Meta || {},
    alias: itemsAO3xt2FTe3Meta?.alias || [],
    redirect: itemsAO3xt2FTe3Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/items.vue").then(m => m.default || m)
  },
  {
    name: reviewksVtklIBnAMeta?.name ?? "minilu-account-returns-checkout-review",
    path: reviewksVtklIBnAMeta?.path ?? "/minilu/account/returns/checkout/review",
    meta: reviewksVtklIBnAMeta || {},
    alias: reviewksVtklIBnAMeta?.alias || [],
    redirect: reviewksVtklIBnAMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/review.vue").then(m => m.default || m)
  },
  {
    name: shippingwt8ShkDLm1Meta?.name ?? "minilu-account-returns-checkout-shipping",
    path: shippingwt8ShkDLm1Meta?.path ?? "/minilu/account/returns/checkout/shipping",
    meta: shippingwt8ShkDLm1Meta || {},
    alias: shippingwt8ShkDLm1Meta?.alias || [],
    redirect: shippingwt8ShkDLm1Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: form65X6XGcv4GMeta?.name ?? "minilu-account-returns-complaint-form",
    path: form65X6XGcv4GMeta?.path ?? "/minilu/account/returns/complaint/form",
    meta: form65X6XGcv4GMeta || {},
    alias: form65X6XGcv4GMeta?.alias || [],
    redirect: form65X6XGcv4GMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/complaint/form.vue").then(m => m.default || m)
  },
  {
    name: indexEfZoSMtp8lMeta?.name ?? "minilu-account-returns",
    path: indexEfZoSMtp8lMeta?.path ?? "/minilu/account/returns",
    meta: indexEfZoSMtp8lMeta || {},
    alias: indexEfZoSMtp8lMeta?.alias || [],
    redirect: indexEfZoSMtp8lMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/account/returns/index.vue").then(m => m.default || m)
  },
  {
    name: addressBMGYdPyvExMeta?.name ?? "minilu-checkout-address",
    path: addressBMGYdPyvExMeta?.path ?? "/minilu/checkout/address",
    meta: addressBMGYdPyvExMeta || {},
    alias: addressBMGYdPyvExMeta?.alias || [],
    redirect: addressBMGYdPyvExMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/address.vue").then(m => m.default || m)
  },
  {
    name: basket8dDG7bvDGPMeta?.name ?? "minilu-checkout-basket",
    path: basket8dDG7bvDGPMeta?.path ?? "/minilu/checkout/basket",
    meta: basket8dDG7bvDGPMeta || {},
    alias: basket8dDG7bvDGPMeta?.alias || [],
    redirect: basket8dDG7bvDGPMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/basket.vue").then(m => m.default || m)
  },
  {
    name: loginmCbiwu2UMKMeta?.name ?? "minilu-checkout-login",
    path: loginmCbiwu2UMKMeta?.path ?? "/minilu/checkout/login",
    meta: loginmCbiwu2UMKMeta || {},
    alias: loginmCbiwu2UMKMeta?.alias || [],
    redirect: loginmCbiwu2UMKMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/login.vue").then(m => m.default || m)
  },
  {
    name: payment_45shipping0zq9O1Sr6MMeta?.name ?? "minilu-checkout-payment-shipping",
    path: payment_45shipping0zq9O1Sr6MMeta?.path ?? "/minilu/checkout/payment-shipping",
    meta: payment_45shipping0zq9O1Sr6MMeta || {},
    alias: payment_45shipping0zq9O1Sr6MMeta?.alias || [],
    redirect: payment_45shipping0zq9O1Sr6MMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/payment-shipping.vue").then(m => m.default || m)
  },
  {
    name: reviewzmysQPdBTXMeta?.name ?? "minilu-checkout-review",
    path: reviewzmysQPdBTXMeta?.path ?? "/minilu/checkout/review",
    meta: reviewzmysQPdBTXMeta || {},
    alias: reviewzmysQPdBTXMeta?.alias || [],
    redirect: reviewzmysQPdBTXMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/checkout/review.vue").then(m => m.default || m)
  },
  {
    name: theme_45testzka5xSUxHAMeta?.name ?? "minilu-debug-theme-test",
    path: theme_45testzka5xSUxHAMeta?.path ?? "/minilu/debug/theme-test",
    meta: theme_45testzka5xSUxHAMeta || {},
    alias: theme_45testzka5xSUxHAMeta?.alias || [],
    redirect: theme_45testzka5xSUxHAMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/debug/theme-test.vue").then(m => m.default || m)
  },
  {
    name: toolwf09nIEBqWMeta?.name ?? "minilu-debug-tool",
    path: toolwf09nIEBqWMeta?.path ?? "/minilu/debug/tool",
    meta: toolwf09nIEBqWMeta || {},
    alias: toolwf09nIEBqWMeta?.alias || [],
    redirect: toolwf09nIEBqWMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/debug/tool.vue").then(m => m.default || m)
  },
  {
    name: indexzesY8QrvVsMeta?.name ?? "minilu-login",
    path: indexzesY8QrvVsMeta?.path ?? "/minilu/login",
    meta: indexzesY8QrvVsMeta || {},
    alias: indexzesY8QrvVsMeta?.alias || [],
    redirect: indexzesY8QrvVsMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/login/index.vue").then(m => m.default || m)
  },
  {
    name: successTIJpI9beVhMeta?.name ?? "minilu-login-success",
    path: successTIJpI9beVhMeta?.path ?? "/minilu/login/success",
    meta: successTIJpI9beVhMeta || {},
    alias: successTIJpI9beVhMeta?.alias || [],
    redirect: successTIJpI9beVhMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/login/success.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93g2K4FeHrg8Meta?.name ?? "minilu-preview-banner-slug",
    path: _91slug_93g2K4FeHrg8Meta?.path ?? "/minilu/preview/banner/:slug()",
    meta: _91slug_93g2K4FeHrg8Meta || {},
    alias: _91slug_93g2K4FeHrg8Meta?.alias || [],
    redirect: _91slug_93g2K4FeHrg8Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/preview/banner/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexm3GxtqUNkMMeta?.name ?? "minilu-productlists-auto-delivery",
    path: indexm3GxtqUNkMMeta?.path ?? "/minilu/productlists/auto-delivery",
    meta: indexm3GxtqUNkMMeta || {},
    alias: indexm3GxtqUNkMMeta?.alias || [],
    redirect: indexm3GxtqUNkMMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/auto-delivery/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93doKGxtHXu9Meta?.name ?? "minilu-productlists-favorites-slug-id",
    path: _91id_93doKGxtHXu9Meta?.path ?? "/minilu/productlists/favorites/:slug(.*)*/:id()",
    meta: _91id_93doKGxtHXu9Meta || {},
    alias: _91id_93doKGxtHXu9Meta?.alias || [],
    redirect: _91id_93doKGxtHXu9Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/favorites/[...slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: index716hBx4BlnMeta?.name ?? "minilu-productlists-favorites",
    path: index716hBx4BlnMeta?.path ?? "/minilu/productlists/favorites",
    meta: index716hBx4BlnMeta || {},
    alias: index716hBx4BlnMeta?.alias || [],
    redirect: index716hBx4BlnMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: indexubYHqZB4TgMeta?.name ?? "minilu-productlists",
    path: indexubYHqZB4TgMeta?.path ?? "/minilu/productlists",
    meta: indexubYHqZB4TgMeta || {},
    alias: indexubYHqZB4TgMeta?.alias || [],
    redirect: indexubYHqZB4TgMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/index.vue").then(m => m.default || m)
  },
  {
    name: indexIPBiP67HfyMeta?.name ?? "minilu-productlists-order-alarm",
    path: indexIPBiP67HfyMeta?.path ?? "/minilu/productlists/order-alarm",
    meta: indexIPBiP67HfyMeta || {},
    alias: indexIPBiP67HfyMeta?.alias || [],
    redirect: indexIPBiP67HfyMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/order-alarm/index.vue").then(m => m.default || m)
  },
  {
    name: indexxOSVtwiqQ5Meta?.name ?? "minilu-productlists-ordered-products",
    path: indexxOSVtwiqQ5Meta?.path ?? "/minilu/productlists/ordered-products",
    meta: indexxOSVtwiqQ5Meta || {},
    alias: indexxOSVtwiqQ5Meta?.alias || [],
    redirect: indexxOSVtwiqQ5Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/ordered-products/index.vue").then(m => m.default || m)
  },
  {
    name: indexFS3mBU2aAnMeta?.name ?? "minilu-productlists-viewed-products",
    path: indexFS3mBU2aAnMeta?.path ?? "/minilu/productlists/viewed-products",
    meta: indexFS3mBU2aAnMeta || {},
    alias: indexFS3mBU2aAnMeta?.alias || [],
    redirect: indexFS3mBU2aAnMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/productlists/viewed-products/index.vue").then(m => m.default || m)
  },
  {
    name: indexsU3FSiLt1OMeta?.name ?? "minilu-register",
    path: indexsU3FSiLt1OMeta?.path ?? "/minilu/register",
    meta: indexsU3FSiLt1OMeta || {},
    alias: indexsU3FSiLt1OMeta?.alias || [],
    redirect: indexsU3FSiLt1OMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/register/index.vue").then(m => m.default || m)
  },
  {
    name: successY4rzAFJGECMeta?.name ?? "minilu-register-success",
    path: successY4rzAFJGECMeta?.path ?? "/minilu/register/success",
    meta: successY4rzAFJGECMeta || {},
    alias: successY4rzAFJGECMeta?.alias || [],
    redirect: successY4rzAFJGECMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/register/success.vue").then(m => m.default || m)
  },
  {
    name: checkoutU3ngDoQeACMeta?.name ?? "minilu-repairshop-checkout",
    path: checkoutU3ngDoQeACMeta?.path ?? "/minilu/repairshop/checkout",
    meta: checkoutU3ngDoQeACMeta || {},
    alias: checkoutU3ngDoQeACMeta?.alias || [],
    redirect: checkoutU3ngDoQeACMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/repairshop/checkout.vue").then(m => m.default || m)
  },
  {
    name: index6o7h0b6h2xMeta?.name ?? "minilu-sales-productId",
    path: index6o7h0b6h2xMeta?.path ?? "/minilu/sales/:productId()",
    meta: index6o7h0b6h2xMeta || {},
    alias: index6o7h0b6h2xMeta?.alias || [],
    redirect: index6o7h0b6h2xMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/sales/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNdW7ClDT4LMeta?.name ?? "minilu-shop-slug",
    path: indexNdW7ClDT4LMeta?.path ?? "/minilu/shop/:slug(.*)*",
    meta: indexNdW7ClDT4LMeta || {},
    alias: indexNdW7ClDT4LMeta?.alias || [],
    redirect: indexNdW7ClDT4LMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDdvrasJ11OMeta?.name ?? "minilu-shop-manufacturer-slug",
    path: indexDdvrasJ11OMeta?.path ?? "/minilu/shop/manufacturer/:slug(.*)*",
    meta: indexDdvrasJ11OMeta || {},
    alias: indexDdvrasJ11OMeta?.alias || [],
    redirect: indexDdvrasJ11OMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/manufacturer/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ESuobqRFiwMeta?.name ?? "minilu-shop-product-slug-id",
    path: _91id_93ESuobqRFiwMeta?.path ?? "/minilu/shop/product/:slug(.*)*/:id()",
    meta: _91id_93ESuobqRFiwMeta || {},
    alias: _91id_93ESuobqRFiwMeta?.alias || [],
    redirect: _91id_93ESuobqRFiwMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/product/[...slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexOjpei7QxtcMeta?.name ?? "minilu-shop-search-slug",
    path: indexOjpei7QxtcMeta?.path ?? "/minilu/shop/search/:slug(.*)*",
    meta: indexOjpei7QxtcMeta || {},
    alias: indexOjpei7QxtcMeta?.alias || [],
    redirect: indexOjpei7QxtcMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/minilu/shop/search/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: overviewAsqzyEZn5ZMeta?.name ?? "vdv-_-banner-overview",
    path: overviewAsqzyEZn5ZMeta?.path ?? "/vdv/_/banner/overview",
    meta: overviewAsqzyEZn5ZMeta || {},
    alias: overviewAsqzyEZn5ZMeta?.alias || [],
    redirect: overviewAsqzyEZn5ZMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/_/banner/overview.vue").then(m => m.default || m)
  },
  {
    name: index1ozAKkrdaeMeta?.name ?? "vdv-slug",
    path: index1ozAKkrdaeMeta?.path ?? "/vdv/:slug(.*)*",
    meta: index1ozAKkrdaeMeta || {},
    alias: index1ozAKkrdaeMeta?.alias || [],
    redirect: index1ozAKkrdaeMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: thank_45youHOxaLCpcxPMeta?.name ?? "vdv-slug-thank-you",
    path: thank_45youHOxaLCpcxPMeta?.path ?? "/vdv/:slug(.*)*/thank-you",
    meta: thank_45youHOxaLCpcxPMeta || {},
    alias: thank_45youHOxaLCpcxPMeta?.alias || [],
    redirect: thank_45youHOxaLCpcxPMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/[...slug]/thank-you.vue").then(m => m.default || m)
  },
  {
    name: indexxxpelmL8l4Meta?.name ?? "vdv-404",
    path: indexxxpelmL8l4Meta?.path ?? "/vdv/404",
    meta: indexxxpelmL8l4Meta || {},
    alias: indexxxpelmL8l4Meta?.alias || [],
    redirect: indexxxpelmL8l4Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/404/index.vue").then(m => m.default || m)
  },
  {
    name: indexFhwoUCFbGuMeta?.name ?? "vdv-account-changePassword",
    path: indexFhwoUCFbGuMeta?.path ?? "/vdv/account/changePassword",
    meta: indexFhwoUCFbGuMeta || {},
    alias: indexFhwoUCFbGuMeta?.alias || [],
    redirect: indexFhwoUCFbGuMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/changePassword/index.vue").then(m => m.default || m)
  },
  {
    name: successvE16D59m1YMeta?.name ?? "vdv-account-changePassword-success",
    path: successvE16D59m1YMeta?.path ?? "/vdv/account/changePassword/success",
    meta: successvE16D59m1YMeta || {},
    alias: successvE16D59m1YMeta?.alias || [],
    redirect: successvE16D59m1YMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/changePassword/success.vue").then(m => m.default || m)
  },
  {
    name: indexcjAh9gop6eMeta?.name ?? "vdv-account",
    path: indexcjAh9gop6eMeta?.path ?? "/vdv/account",
    meta: indexcjAh9gop6eMeta || {},
    alias: indexcjAh9gop6eMeta?.alias || [],
    redirect: indexcjAh9gop6eMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexPDdiQIauA0Meta?.name ?? "vdv-account-orders",
    path: indexPDdiQIauA0Meta?.path ?? "/vdv/account/orders",
    meta: indexPDdiQIauA0Meta || {},
    alias: indexPDdiQIauA0Meta?.alias || [],
    redirect: indexPDdiQIauA0Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexEieqmBKy3uMeta?.name ?? "vdv-account-profile",
    path: indexEieqmBKy3uMeta?.path ?? "/vdv/account/profile",
    meta: indexEieqmBKy3uMeta || {},
    alias: indexEieqmBKy3uMeta?.alias || [],
    redirect: indexEieqmBKy3uMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexhpY3B3Vnd5Meta?.name ?? "vdv-account-resetPassword",
    path: indexhpY3B3Vnd5Meta?.path ?? "/vdv/account/resetPassword",
    meta: indexhpY3B3Vnd5Meta || {},
    alias: indexhpY3B3Vnd5Meta?.alias || [],
    redirect: indexhpY3B3Vnd5Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/resetPassword/index.vue").then(m => m.default || m)
  },
  {
    name: successIaW4ZhmhYlMeta?.name ?? "vdv-account-resetPassword-success",
    path: successIaW4ZhmhYlMeta?.path ?? "/vdv/account/resetPassword/success",
    meta: successIaW4ZhmhYlMeta || {},
    alias: successIaW4ZhmhYlMeta?.alias || [],
    redirect: successIaW4ZhmhYlMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/resetPassword/success.vue").then(m => m.default || m)
  },
  {
    name: itemsUyjyPt4NFOMeta?.name ?? "vdv-account-returns-checkout-items",
    path: itemsUyjyPt4NFOMeta?.path ?? "/vdv/account/returns/checkout/items",
    meta: itemsUyjyPt4NFOMeta || {},
    alias: itemsUyjyPt4NFOMeta?.alias || [],
    redirect: itemsUyjyPt4NFOMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/items.vue").then(m => m.default || m)
  },
  {
    name: reviewheBh9Cr9HNMeta?.name ?? "vdv-account-returns-checkout-review",
    path: reviewheBh9Cr9HNMeta?.path ?? "/vdv/account/returns/checkout/review",
    meta: reviewheBh9Cr9HNMeta || {},
    alias: reviewheBh9Cr9HNMeta?.alias || [],
    redirect: reviewheBh9Cr9HNMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/review.vue").then(m => m.default || m)
  },
  {
    name: shippingUiXg2kpUm6Meta?.name ?? "vdv-account-returns-checkout-shipping",
    path: shippingUiXg2kpUm6Meta?.path ?? "/vdv/account/returns/checkout/shipping",
    meta: shippingUiXg2kpUm6Meta || {},
    alias: shippingUiXg2kpUm6Meta?.alias || [],
    redirect: shippingUiXg2kpUm6Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: formE3T19pJT0tMeta?.name ?? "vdv-account-returns-complaint-form",
    path: formE3T19pJT0tMeta?.path ?? "/vdv/account/returns/complaint/form",
    meta: formE3T19pJT0tMeta || {},
    alias: formE3T19pJT0tMeta?.alias || [],
    redirect: formE3T19pJT0tMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/complaint/form.vue").then(m => m.default || m)
  },
  {
    name: indexQLpR1SkXrbMeta?.name ?? "vdv-account-returns",
    path: indexQLpR1SkXrbMeta?.path ?? "/vdv/account/returns",
    meta: indexQLpR1SkXrbMeta || {},
    alias: indexQLpR1SkXrbMeta?.alias || [],
    redirect: indexQLpR1SkXrbMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/account/returns/index.vue").then(m => m.default || m)
  },
  {
    name: addressxXtYUUsyrFMeta?.name ?? "vdv-checkout-address",
    path: addressxXtYUUsyrFMeta?.path ?? "/vdv/checkout/address",
    meta: addressxXtYUUsyrFMeta || {},
    alias: addressxXtYUUsyrFMeta?.alias || [],
    redirect: addressxXtYUUsyrFMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/address.vue").then(m => m.default || m)
  },
  {
    name: basketKCoqvpWcnMMeta?.name ?? "vdv-checkout-basket",
    path: basketKCoqvpWcnMMeta?.path ?? "/vdv/checkout/basket",
    meta: basketKCoqvpWcnMMeta || {},
    alias: basketKCoqvpWcnMMeta?.alias || [],
    redirect: basketKCoqvpWcnMMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/basket.vue").then(m => m.default || m)
  },
  {
    name: loginmJtOHFQoWhMeta?.name ?? "vdv-checkout-login",
    path: loginmJtOHFQoWhMeta?.path ?? "/vdv/checkout/login",
    meta: loginmJtOHFQoWhMeta || {},
    alias: loginmJtOHFQoWhMeta?.alias || [],
    redirect: loginmJtOHFQoWhMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/login.vue").then(m => m.default || m)
  },
  {
    name: payment_45shipping8N0RqU7HoGMeta?.name ?? "vdv-checkout-payment-shipping",
    path: payment_45shipping8N0RqU7HoGMeta?.path ?? "/vdv/checkout/payment-shipping",
    meta: payment_45shipping8N0RqU7HoGMeta || {},
    alias: payment_45shipping8N0RqU7HoGMeta?.alias || [],
    redirect: payment_45shipping8N0RqU7HoGMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/payment-shipping.vue").then(m => m.default || m)
  },
  {
    name: reviewHHhlExyKELMeta?.name ?? "vdv-checkout-review",
    path: reviewHHhlExyKELMeta?.path ?? "/vdv/checkout/review",
    meta: reviewHHhlExyKELMeta || {},
    alias: reviewHHhlExyKELMeta?.alias || [],
    redirect: reviewHHhlExyKELMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/checkout/review.vue").then(m => m.default || m)
  },
  {
    name: theme_45testEFsRRySBkYMeta?.name ?? "vdv-debug-theme-test",
    path: theme_45testEFsRRySBkYMeta?.path ?? "/vdv/debug/theme-test",
    meta: theme_45testEFsRRySBkYMeta || {},
    alias: theme_45testEFsRRySBkYMeta?.alias || [],
    redirect: theme_45testEFsRRySBkYMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/debug/theme-test.vue").then(m => m.default || m)
  },
  {
    name: tool3zxr8vsFp8Meta?.name ?? "vdv-debug-tool",
    path: tool3zxr8vsFp8Meta?.path ?? "/vdv/debug/tool",
    meta: tool3zxr8vsFp8Meta || {},
    alias: tool3zxr8vsFp8Meta?.alias || [],
    redirect: tool3zxr8vsFp8Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/debug/tool.vue").then(m => m.default || m)
  },
  {
    name: indexi6eOCaDFSXMeta?.name ?? "vdv-login",
    path: indexi6eOCaDFSXMeta?.path ?? "/vdv/login",
    meta: indexi6eOCaDFSXMeta || {},
    alias: indexi6eOCaDFSXMeta?.alias || [],
    redirect: indexi6eOCaDFSXMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/login/index.vue").then(m => m.default || m)
  },
  {
    name: successLA4eDiasBaMeta?.name ?? "vdv-login-success",
    path: successLA4eDiasBaMeta?.path ?? "/vdv/login/success",
    meta: successLA4eDiasBaMeta || {},
    alias: successLA4eDiasBaMeta?.alias || [],
    redirect: successLA4eDiasBaMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/login/success.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93D6qR8t08XvMeta?.name ?? "vdv-preview-banner-slug",
    path: _91slug_93D6qR8t08XvMeta?.path ?? "/vdv/preview/banner/:slug()",
    meta: _91slug_93D6qR8t08XvMeta || {},
    alias: _91slug_93D6qR8t08XvMeta?.alias || [],
    redirect: _91slug_93D6qR8t08XvMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/preview/banner/[slug].vue").then(m => m.default || m)
  },
  {
    name: index1qujTxPysJMeta?.name ?? "vdv-productlists-auto-delivery",
    path: index1qujTxPysJMeta?.path ?? "/vdv/productlists/auto-delivery",
    meta: index1qujTxPysJMeta || {},
    alias: index1qujTxPysJMeta?.alias || [],
    redirect: index1qujTxPysJMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/auto-delivery/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yIjhBluM7fMeta?.name ?? "vdv-productlists-favorites-slug-id",
    path: _91id_93yIjhBluM7fMeta?.path ?? "/vdv/productlists/favorites/:slug(.*)*/:id()",
    meta: _91id_93yIjhBluM7fMeta || {},
    alias: _91id_93yIjhBluM7fMeta?.alias || [],
    redirect: _91id_93yIjhBluM7fMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/favorites/[...slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexvuyMHXIqMGMeta?.name ?? "vdv-productlists-favorites",
    path: indexvuyMHXIqMGMeta?.path ?? "/vdv/productlists/favorites",
    meta: indexvuyMHXIqMGMeta || {},
    alias: indexvuyMHXIqMGMeta?.alias || [],
    redirect: indexvuyMHXIqMGMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: indexzc2pzVJcDQMeta?.name ?? "vdv-productlists",
    path: indexzc2pzVJcDQMeta?.path ?? "/vdv/productlists",
    meta: indexzc2pzVJcDQMeta || {},
    alias: indexzc2pzVJcDQMeta?.alias || [],
    redirect: indexzc2pzVJcDQMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/index.vue").then(m => m.default || m)
  },
  {
    name: indexBWMR4UtkyDMeta?.name ?? "vdv-productlists-order-alarm",
    path: indexBWMR4UtkyDMeta?.path ?? "/vdv/productlists/order-alarm",
    meta: indexBWMR4UtkyDMeta || {},
    alias: indexBWMR4UtkyDMeta?.alias || [],
    redirect: indexBWMR4UtkyDMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/order-alarm/index.vue").then(m => m.default || m)
  },
  {
    name: index8wBuIUbBGGMeta?.name ?? "vdv-productlists-ordered-products",
    path: index8wBuIUbBGGMeta?.path ?? "/vdv/productlists/ordered-products",
    meta: index8wBuIUbBGGMeta || {},
    alias: index8wBuIUbBGGMeta?.alias || [],
    redirect: index8wBuIUbBGGMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/ordered-products/index.vue").then(m => m.default || m)
  },
  {
    name: index5ZvWiFwnmIMeta?.name ?? "vdv-productlists-viewed-products",
    path: index5ZvWiFwnmIMeta?.path ?? "/vdv/productlists/viewed-products",
    meta: index5ZvWiFwnmIMeta || {},
    alias: index5ZvWiFwnmIMeta?.alias || [],
    redirect: index5ZvWiFwnmIMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/productlists/viewed-products/index.vue").then(m => m.default || m)
  },
  {
    name: index9C1BR7ag0MMeta?.name ?? "vdv-register",
    path: index9C1BR7ag0MMeta?.path ?? "/vdv/register",
    meta: index9C1BR7ag0MMeta || {},
    alias: index9C1BR7ag0MMeta?.alias || [],
    redirect: index9C1BR7ag0MMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/register/index.vue").then(m => m.default || m)
  },
  {
    name: successD4vHILRR9vMeta?.name ?? "vdv-register-success",
    path: successD4vHILRR9vMeta?.path ?? "/vdv/register/success",
    meta: successD4vHILRR9vMeta || {},
    alias: successD4vHILRR9vMeta?.alias || [],
    redirect: successD4vHILRR9vMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/register/success.vue").then(m => m.default || m)
  },
  {
    name: checkoutPjEsrXvMAfMeta?.name ?? "vdv-repairshop-checkout",
    path: checkoutPjEsrXvMAfMeta?.path ?? "/vdv/repairshop/checkout",
    meta: checkoutPjEsrXvMAfMeta || {},
    alias: checkoutPjEsrXvMAfMeta?.alias || [],
    redirect: checkoutPjEsrXvMAfMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/repairshop/checkout.vue").then(m => m.default || m)
  },
  {
    name: indexkFY0baP8tOMeta?.name ?? "vdv-sales-productId",
    path: indexkFY0baP8tOMeta?.path ?? "/vdv/sales/:productId()",
    meta: indexkFY0baP8tOMeta || {},
    alias: indexkFY0baP8tOMeta?.alias || [],
    redirect: indexkFY0baP8tOMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/sales/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexaUv5nYhlDXMeta?.name ?? "vdv-shop-slug",
    path: indexaUv5nYhlDXMeta?.path ?? "/vdv/shop/:slug(.*)*",
    meta: indexaUv5nYhlDXMeta || {},
    alias: indexaUv5nYhlDXMeta?.alias || [],
    redirect: indexaUv5nYhlDXMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index6aixs50fOZMeta?.name ?? "vdv-shop-manufacturer-slug",
    path: index6aixs50fOZMeta?.path ?? "/vdv/shop/manufacturer/:slug(.*)*",
    meta: index6aixs50fOZMeta || {},
    alias: index6aixs50fOZMeta?.alias || [],
    redirect: index6aixs50fOZMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/manufacturer/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93MNvCWplEX5Meta?.name ?? "vdv-shop-product-slug-id",
    path: _91id_93MNvCWplEX5Meta?.path ?? "/vdv/shop/product/:slug(.*)*/:id()",
    meta: _91id_93MNvCWplEX5Meta || {},
    alias: _91id_93MNvCWplEX5Meta?.alias || [],
    redirect: _91id_93MNvCWplEX5Meta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/product/[...slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexr3g6CYwSIkMeta?.name ?? "vdv-shop-search-slug",
    path: indexr3g6CYwSIkMeta?.path ?? "/vdv/shop/search/:slug(.*)*",
    meta: indexr3g6CYwSIkMeta || {},
    alias: indexr3g6CYwSIkMeta?.alias || [],
    redirect: indexr3g6CYwSIkMeta?.redirect,
    component: () => import("/home/vsts/work/1/s/frontend/app/src/pages/vdv/shop/search/[...slug]/index.vue").then(m => m.default || m)
  }
]