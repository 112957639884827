import { defineStore } from 'pinia';
import storeIds from './storeIds';
import type { NavItem } from '~~/src/server/transformers/transformMainNavItem';
import type { AllNavItems } from '~/server/gateway/connections/cms/loaders/navigation/loadAllNavItems';

export const useNav = defineStore(storeIds.NAVIGATION, {
  state: () => ({
    mainNav: {
      navItems: [] as LinkedNavItem[],
      activeItem: null as LinkedNavItem,
    },
    toggleNav: {
      navItems: [] as LinkedNavItem[],
    },
    footerNav: {
      navItems: [] as LinkedNavItem[],
    },
    layout: 'default',
    layoutBGColor: '',
  }),

  getters: {
    /**
     * @Info
     * Provides the nav-structure for the active item in proper order
     * You could see the whole hierachical structure via iterating: Parents, activeItem, Children
     */
    mainNav_activeStructure: (state) => {
      const navStructure = {
        parents: [],
        activeItem: null,
        children: [],
      } as NavStructure;

      if (!state.mainNav.activeItem) {
        return navStructure;
      }

      let parent = state.mainNav.activeItem.parent;
      while (parent) {
        navStructure.parents.push(parent);
        parent = parent.parent;
      }

      navStructure.parents.reverse();
      navStructure.children = state.mainNav.activeItem.children ?? [];
      navStructure.activeItem = state.mainNav.activeItem;

      return navStructure;
    },
  },

  actions: {
    async init(navData: { all: AllNavItems; current: string }) {
      this.mainNav.navItems = generateLinkedNavStructure(navData.all.mainNav);
      this.toggleNav.navItems = generateLinkedNavStructure(
        navData.all.toggleNav,
      );
      this.footerNav.navItems = generateLinkedNavStructure(
        navData.all.footerNav,
      );

      this.mainNav_setActiveItemById(navData.current);

      useRouter().beforeEach(() => {
        this.setLayoutBgColor('');
      });
    },

    mainNav_setActiveItemByUri(uri: string) {
      const foundNavItem =
        this.mainNav.navItems.find((x) => x.navUri === uri) ??
        this.mainNav.activeItem ??
        this.mainNav.navItems.find((x) => x.level === 0);
      this.mainNav.activeItem = foundNavItem;
    },
    mainNav_setActiveItemById(id: string) {
      const foundNavItem =
        this.mainNav.navItems.find((x) => x.id === id) ??
        this.mainNav.navItems.find((x) => x.level === 0) ??
        this.mainNav.activeItem;
      this.mainNav.activeItem = foundNavItem;
    },
    setLayoutBgColor(color: string) {
      this.layoutBGColor = color;
    },
    getNavItemByNavUri(uri: string) {
      return this.mainNav.navItems.find((x) => uri === x.navUri);
    },
  },
});

/**
 * @Info
 * Creates an array of nav-items that reference eachother (parents, children, neighbours will contain and point to other nav-items)
 * Do not try to convert this structure to a JSON/String as it will not work due to the circular dependencies
 */
export function generateLinkedNavStructure(navItems: NavItem[]) {
  //Create Objects to be linked
  const linkedNavItems: LinkedNavItem[] = navItems.map((navItem) => {
    return {
      ...navItem,
      parent: null,
      children: [],
      neighbours: [],
    };
  });

  //Link Parents & Children
  navItems.forEach(({ id, parentId, childIds }) => {
    const linkedNavItem = linkedNavItems.find((x) => x.id === id);
    linkedNavItem.parent = linkedNavItems.find((x) => x.id === parentId);
    childIds.forEach((childId) => {
      const child = linkedNavItems.find((x) => x.id === childId);
      if (child) linkedNavItem.children.push(child);
    });
  });

  //Link Neighbours (needs linked parents first)
  navItems.forEach(({ id }) => {
    const linkedNavItem = linkedNavItems.find((x) => x.id === id);
    linkedNavItem.neighbours = linkedNavItems.filter(
      (x) =>
        x.level === linkedNavItem.level &&
        x.parent?.id === linkedNavItem.parent?.id,
    );
  });
  return linkedNavItems;
}
/** @param neighbours Includes self - Filter out when not needed */
export interface LinkedNavItem extends NavItem {
  parent: LinkedNavItem;
  children: LinkedNavItem[];
  neighbours: LinkedNavItem[];
}

export type NavStructure = {
  parents: LinkedNavItem[];
  activeItem: LinkedNavItem;
  children: LinkedNavItem[];
};
