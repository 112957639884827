import { type Areas_Area_Entry } from '~~/src/gql/schema';
import { type IconData, transformIcon } from '../iconTransformer';
import type { ISectionColor } from '~~/src/@types/section-color';
import { EAreaType } from '~~/src/@types/area';
import { SiteIdent } from '~/@types/siteIdent';

const CssVar_GeneralAppendices = ['base', 'alpha', 'beta', 'light'];

export interface AreaData {
  id: string;
  title?: string;
  color?: string;
  fontColor?: string;
  description?: string;
  icon?: IconData;
  alpha?: string;
  newsletterImage?: string;
}

export function transformArea(data: Areas_Area_Entry): AreaData {
  return {
    id: data.id,
    title: data.title,
    color: data.areaColour,
    description: data.areaDescription,
    icon: transformIcon(data.areaIcon),
    alpha: data.areaColorTransparency,
    fontColor: data.areaFontColour,
    newsletterImage: getNewsletterImage(data.id),
  };
}

export function getNewsletterImage(areaId: string): string | null {
  switch (areaId) {
    case EAreaType.ACADEMY:
      return '@/assets/images/newsletter-calender-academy.svg';
    case EAreaType.SERVICE:
      return '@/assets/images/newsletter-calender-service.svg';
    default:
      return '';
  }
}

export function getAreaColors(
  sectionColor: ISectionColor,
  site: SiteIdent,
  isDefaultArea: boolean,
) {
  const decoColor = { base: '', alpha: '' };
  const fontColor = { base: '', alpha: '' };
  const borderColor = { base: '', alpha: '' };
  const baseStyles = [];
  for (const appendix of CssVar_GeneralAppendices) {
    baseStyles.push(
      `--${sectionColor.id}-${appendix}:${
        sectionColor[appendix as keyof ISectionColor]
      }`,
    );
  }

  //todo change
  if (site === SiteIdent.default && isDefaultArea) {
    //vdv area
    decoColor.base = 'var(--vdv-base)';
    decoColor.alpha = 'var(--vdv-font-alpha)';
    fontColor.alpha = 'var(--vdv-font)';
    borderColor.base = 'var(--vdv-border-dark)';
    borderColor.alpha = 'var(--vdv-border-light)';
  } else if (site.startsWith('minilu')) {
    //set minilu-colors for minilu areas
    decoColor.base = 'var(--minilu-headline-stroke)';
    decoColor.alpha = 'var(--minilu-base)';
    fontColor.alpha = 'var(--minilu-font)';
    borderColor.base = 'var(--minilu-border-dark)';
    borderColor.alpha = 'var(--minilu-border-light)';
  } else if (site === SiteIdent.default) {
    //default colors for vdv-areas
    decoColor.base = 'var(--vdv-font-alpha)';
    decoColor.alpha = 'var(--vdv-base)';
    fontColor.alpha = 'var(--vdv-font)';
    borderColor.base = 'var(--vdv-border-light)';
    borderColor.alpha = 'var(--vdv-border-dark)';
  }
  return [
    `--${sectionColor.id}-font-base:${sectionColor.font}`,
    `--${sectionColor.id}-font-alpha:${fontColor.alpha}`,
    `--${sectionColor.id}-deco-base:${decoColor.base}`,
    `--${sectionColor.id}-deco-alpha:${decoColor.alpha}`,
    `--${sectionColor.id}-border-base:${borderColor.base}`,
    `--${sectionColor.id}-border-alpha:${borderColor.alpha}`,
  ].concat(baseStyles);
}

export function generateSectionColor(area: AreaData): ISectionColor {
  const sectionColor = {
    id: area.id,
    base: area.color,
    alpha: '',
    beta: `${area.color}CC`,
    light: `${area.color}4D`,
    font: `${area.fontColor}`,
  };
  if (area.alpha) {
    sectionColor.alpha = area.alpha;
  } else {
    sectionColor.alpha = area.color.toString() + '4D';
  }
  return sectionColor;
}
