import { useCheckoutStore } from '@/stores/useCheckout';
import { useCartStore } from '@/stores/useCart';
import { UseStateKeys } from '~/useStateKeys';
import type { BankData } from '~/components/shop/checkout/payment/types';

/**
 * Resets BankdataInput on Checkout leave & removes hints from Cart on review leave
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!import.meta.client) return;

  if (!isNotInCheckout(from.path) && isNotInCheckout(to.path)) {
    await resetBankDataInput();
    useCheckoutStore().$reset();
  } else {
    useCheckoutStore().resetShowHints();
  }

  if (from.path === '/checkout/review') {
    useCartStore().removeHints();
  }
});

async function resetBankDataInput() {
  const bankDataInput = useState<BankData>(
    UseStateKeys.BANKDATA_INPUT_CHECKOUT,
  );
  bankDataInput.value = {
    bankName: '',
    bic: '',
    iban: '',
    error: ['BANKDATA_INCOMPLETE'],
  };
}

function isNotInCheckout(path: string) {
  return (
    !path.startsWith('/checkout') ||
    path === '/checkout/basket' ||
    path === '/checkout/thank-you'
  );
}
