/**
 * @description
 * Use offset to correct scrolling if there's a sticky header for example,
 * or if you want to leave some breathing room above the object you want to scroll to.
 */
export function scrollToElement(element: Element, offset = 0): void {
  if (!import.meta.server && element) {
    const elementScrollY = element.getBoundingClientRect().top;
    const bodyY = document.body.getBoundingClientRect().top;
    window.scrollTo({
      top: elementScrollY - bodyY - offset,
      behavior: 'smooth',
    });
  }
}
