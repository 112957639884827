import {
  mq_breakpointIsDesktop,
  mq_breakpointIsMobile,
  mq_breakpointIsTablet,
  mq_isTouch,
} from '../injectionSymbols';
import MobileDetect from 'mobile-detect';
export async function useAppWideMediaQuerySetup() {
  const breakpoints = useBreakpoints({
    mobile: 768,
    tablet: 960,
    desktop: 1410,
  });

  const isMobile = breakpoints.smaller('mobile');
  const isTablet = breakpoints.between('mobile', 'tablet');
  const isDesktop = breakpoints.greater('tablet');

  if (import.meta.server) {
    const userAgent = useRequestHeaders(['user-agent'])['user-agent'];
    //this should be dynamically imported, but breaks provides if we do it here? It might work in app.vue directly for some reason.
    const mobileDetect = new MobileDetect(userAgent);
    isMobile.value = mobileDetect.mobile() !== null;
  }
  const isTouch = ref(false);

  if (import.meta.client) {
    isTouch.value = window.matchMedia('(hover: none)').matches;
    isMobile.value = window.matchMedia('(max-width: 768px)').matches;
  }

  provide(mq_breakpointIsMobile, isMobile); // <--- only one working for server side rendering
  provide(mq_breakpointIsTablet, isTablet);
  provide(mq_breakpointIsDesktop, isDesktop);
  provide(mq_isTouch, isTouch);
}

export default useAppWideMediaQuerySetup;
