export default {
  banner: () => import("/home/vsts/work/1/s/frontend/app/src/layouts/banner.vue").then(m => m.default || m),
  default: () => import("/home/vsts/work/1/s/frontend/app/src/layouts/default.vue").then(m => m.default || m),
  minilu: () => import("/home/vsts/work/1/s/frontend/app/src/layouts/minilu.vue").then(m => m.default || m),
  "minilu-checkout-sub-view": () => import("/home/vsts/work/1/s/frontend/app/src/layouts/miniluCheckoutSubView.vue").then(m => m.default || m),
  "minilu-return-checkout-sub-view": () => import("/home/vsts/work/1/s/frontend/app/src/layouts/miniluReturnCheckoutSubView.vue").then(m => m.default || m),
  "minilu-sub-view": () => import("/home/vsts/work/1/s/frontend/app/src/layouts/miniluSubView.vue").then(m => m.default || m),
  "minilu-with-fav-list-nav": () => import("/home/vsts/work/1/s/frontend/app/src/layouts/miniluWithFavListNav.vue").then(m => m.default || m),
  "repairshop-minilu": () => import("/home/vsts/work/1/s/frontend/app/src/layouts/repairshop-minilu.vue").then(m => m.default || m),
  "repairshop-vdv": () => import("/home/vsts/work/1/s/frontend/app/src/layouts/repairshop-vdv.vue").then(m => m.default || m),
  vdv: () => import("/home/vsts/work/1/s/frontend/app/src/layouts/vdv.vue").then(m => m.default || m),
  "vdv-checkout-sub-view": () => import("/home/vsts/work/1/s/frontend/app/src/layouts/vdvCheckoutSubView.vue").then(m => m.default || m),
  "vdv-return-checkout-sub-view": () => import("/home/vsts/work/1/s/frontend/app/src/layouts/vdvReturnCheckoutSubView.vue").then(m => m.default || m),
  "vdv-sub-view": () => import("/home/vsts/work/1/s/frontend/app/src/layouts/vdvSubView.vue").then(m => m.default || m),
  "vdv-with-fav-list-nav": () => import("/home/vsts/work/1/s/frontend/app/src/layouts/vdvWithFavListNav.vue").then(m => m.default || m)
}