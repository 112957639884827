import { useReservation } from '@/stores/useReserevation';

/**
 * @Info
 * All Routing/Redirects and Navigation/Area-Color handling should happen here
 * This composable will be called every time we route somwhere
 * It'll be easier to maintain this way because if something is wrong with those things, we can start debugging in one place
 * As the logic grows, we can easily split it into smaller modules/functions :)
 */

export async function useAfterRouteChangeActions(uri: string) {
  const currentPath = useRoute().path;
  if (currentPath.startsWith('/api')) return;

  // Check if the uri we WERE on when this function was called is still the same uri we ARE on at the moment of execution
  if (uri !== currentPath.replace('/', '')) {
    return;
  }

  // if a URI ends with a "/", reroute to the URI without the "/"
  else if (currentPath.endsWith('/') && currentPath.length > 1) {
    const newPath = currentPath.slice(0, -1);
    //useRouter().replace(newPath);
    await navigateTo({ path: newPath }, { redirectCode: 301, external: true });
  }

  // Thank-You Page
  else if (useRoute().path.endsWith('thank-you')) {
    const reservationStore = useReservation();
    if (!reservationStore.lastReservation) {
      //useRouter().replace('/');
      await navigateTo({ path: '/' }, { redirectCode: 303 });
    }
  }
}
