import { DialogIdent, useDialogStore } from '~/stores/useDialogStore';
import { useSessionStore } from '~/stores/useSessionStore';

export async function useLoginAction() {
  const dialogStore = useDialogStore();
  const session = useSessionStore();
  if (!session.isLoggedIn) {
    await dialogStore.openDialog(DialogIdent.LOGIN, { canRegister: false });
  }
  return { isLoggedIn: session.isLoggedIn };
}
