/* eslint-disable no-console */
import { defineNuxtPlugin } from '#app';
import * as Sentry from '@sentry/vue';
import { browserTracingIntegration } from '@sentry/vue';
import type { Router } from 'vue-router';
import { v4 as uuidv4 } from 'uuid';
import { UseStateKeys } from '@/useStateKeys';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const environment = config.public.ENVIRONMENT_NAME ?? 'local';
  const release = config.public.IMAGE_VERSION ?? 'local';
  const enabled =
    typeof config.public.SENTRY_CLIENT_ENABLE === 'number'
      ? config.public.SENTRY_CLIENT_ENABLE === 1
      : config.public.SENTRY_CLIENT_ENABLE === '1';
  const dsn = config.public.SENTRY_DSN;

  const { vueApp } = nuxtApp;
  if (!dsn || !enabled) {
    return;
  }

  let sentryConfig: Parameters<(typeof Sentry)['init']>[0] = {
    app: [vueApp],
    dsn,
    integrations: [
      browserTracingIntegration({ router: nuxtApp.$router as Router }),
    ],
    sampleRate: config.public.SENTRY_PROFILING_RATE
      ? parseFloat(config.public.SENTRY_PROFILING_RATE)
      : 1,
    tracesSampleRate: config.public.SENTRY_TRACING_RATE
      ? parseFloat(config.public.SENTRY_TRACING_RATE)
      : 1,
    tracePropagationTargets: [
      window.location.origin,
      'localhost',
      'https://shop-dev.minilu.de',
      'https://shop-staging.minilu.de',
      '/api',
    ],
    environment,
    release,
  };

  if (environment?.toLowerCase() === 'staging' || environment === 'local') {
    sentryConfig = {
      ...sentryConfig,
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: 1.0,
    };
    (<Array<any>>sentryConfig.integrations).push(
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        stickySession: false,
        networkDetailAllowUrls: [
          window.location.origin,
          'localhost',
          'https://shop-dev.minilu.de',
          'https://shop-staging.minilu.de',
          '/api',
        ],
        networkCaptureBodies: true,
      }),
    );
  }

  Sentry.init(sentryConfig);

  const userSessionId = useState(UseStateKeys.SENTRY_USER_ID, () => {
    return uuidv4() as string;
  });
  Sentry.setUser({ id: userSessionId.value });
  console.info('✨ Sentry initialized');
});
