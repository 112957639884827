export default function useValidateBankData(
  bankName: string,
  iban: string,
  bic: string,
) {
  const bankNameOK = checkBankName(bankName);
  const ibanOK = checkIBAN(iban);
  const bicOK = checkBic(bic);
  const allOK = bankNameOK && ibanOK && bicOK;
  return {
    bankNameOK,
    ibanOK,
    bicOK,
    allOK,
  };
}

export function checkBic(bic: string) {
  if (!bic || bic?.length < 3) {
    return false;
  }
  return true;
}

export function checkBankName(bankName: string) {
  if (!bankName || bankName?.length < 3) {
    return false;
  }
  return true;
}

export function checkIBAN(iban: string) {
  if (!iban || iban.length < 1 || iban === '') return false;
  // Normalize input (remove spaces and make upcase)
  iban = iban.replace(/ /g, '').toUpperCase();

  if (/^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/.test(iban)) {
    const country = iban.substring(0, 2);
    const check = parseInt(iban.substring(2, 4), 10);
    const account = iban.substring(4);

    // To numeric representation
    const search = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const replace = [];
    for (let i = 10; i <= 35; i++) {
      replace.push(i.toString());
    }
    let numstr = account + country + '00';
    for (let i = 0; i < search.length; i++) {
      numstr = numstr.replace(new RegExp(search[i], 'g'), replace[i]);
    }

    // Calculate checksum
    let checksum = parseInt(numstr.substring(0, 1), 10);
    for (let pos = 1; pos < numstr.length; pos++) {
      checksum *= 10;
      checksum += parseInt(numstr.substring(pos, pos + 1), 10);
      checksum %= 97;
    }

    return 98 - checksum === check;
  } else {
    return false;
  }
}
