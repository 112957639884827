import { config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
//import { fab } from '@fortawesome/free-brands-svg-icons';
//import { fas } from '@fortawesome/pro-solid-svg-icons';
//import { fal } from '@fortawesome/pro-light-svg-icons';
//import { far } from '@fortawesome/pro-regular-svg-icons';

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false;

// THIS DOESNT WORK WITH SSR FOR SOME REASON
//library.add(fas);
//library.add(fal);
//library.add(far);
//library.add(fab);

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon);
});
