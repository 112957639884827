export function useScrollbarWidth() {
  if (import.meta.client) {
    onMounted(() => {
      setTimeout(() => {
        setScrollWidth();
        window.addEventListener('resize', () => {
          setTimeout(() => {
            setScrollWidth();
          }, 0);
        });
      }, 0);
    });
  }
}

function setScrollWidth() {
  const scrollbarWidth =
    window.innerWidth - document.documentElement.clientWidth;
  document.documentElement.style.setProperty(
    '--scrollbar-width',
    `${scrollbarWidth}px`,
  );
}
