import useLoadingDialog from './dialogs/useLoadingDialog';

/**
 * warpper for any promise to show loading dialog after 5 seconds
 */
export async function useLongLoading<T>(p: Promise<T>): Promise<T> {
  const dialog = useLoadingDialog();

  const showLoadingTimeout = setTimeout(() => {
    dialog.open('loading.longLoadingTime');
  }, 5000);

  return p.finally(() => {
    clearTimeout(showLoadingTimeout);
    dialog.close();
  });
}
