export type WithData<T = any, D = any> = T & {
  data: D;
};

export enum SiteIdent {
  miniluDe = 'miniluDe',
  miniluAt = 'miniluAt',
  miniluNl = 'miniluNl',
  default = 'default',
}
