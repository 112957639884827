import { defineStore } from 'pinia';
import StoreIds from './storeIds';
import {
  useSecureSessionPost,
  useSessionGet,
} from '~/composables/dataFetching/genericFetchers';
import { ListType } from '~/@types/favList';
import type {
  FavoriteList,
  FavoriteListItem,
} from '~/server/transformers/shop/favoriteListTransformer';
import type { Product } from '~/server/transformers/shop/product/types';
import type { PostBody as AddItemsBody } from '~/server/api/[site]/user/favList/addItems.post';
import { useSessionStore } from './useSessionStore';
import { handleLoadingError } from '~/utils/handleLoadingError';

interface TempAssignment {
  productId: string;
  listId: string;
  action: 'add' | 'delete';
  handeled?: boolean;
}

export type State = {
  lists: Record<ListType, FavoriteList[] | FavoriteList>;
  initialized: boolean;
  offlineInitialized: boolean;
  listReloadPing: boolean;
  isloading: boolean;
  offlineViewdListItems: { id: string; lastViewed: string }[];
  multiselect: {
    listId: string;
    selectedItems: FavoriteListItem[];
    moveToList: FavoriteList;
    itemsForSelectAll: string[];
    showMultiSelect: boolean;
    showSuccessBannerDelete: boolean;
    showSuccessBannerMove: boolean;
  };
  temp_multiselect: {
    listId: string;
    selectedItems: FavoriteListItem[];
    moveToList: FavoriteList;
  } | null;
  _loadingIndex: number;
  _tempAssignments: TempAssignment[];
};

const offlineViewedListStorageKey = 'offlineViewedList';

export const useFavLists = defineStore(StoreIds.FAV_LISTS, {
  state: () =>
    ({
      lists: {},
      initialized: false,
      offlineInitialized: false,
      listReloadPing: false,
      offlineViewdListItems: [],
      multiselect: {
        listId: null,
        selectedItems: [],
        moveToList: null,
        itemsForSelectAll: [],
        showMultiSelect: false,
        showSuccessBannerDelete: false,
        showSuccessBannerMove: false,
      },
      temp_multiselect: null,
      _loadingIndex: 0,
      _tempAssignments: [],
    }) as State,
  getters: {
    viewedProductsList(state): FavoriteList {
      const list = state.lists[ListType.VIEWED];
      if (Array.isArray(list)) return list[0];
      return list ?? null;
    },
    orderedProductsList(state): FavoriteList {
      const list = state.lists[ListType.ORDERED];
      if (Array.isArray(list)) return list[0];
      return list ?? null;
    },
    myFavoriteList(state): FavoriteList {
      const list = state.lists[ListType.FAVORITES];
      if (Array.isArray(list)) return list[0];
      return list ?? null;
    },
    favoriteLists(state): FavoriteList[] {
      const lists = state.lists[ListType.DEFAULT];
      if (Array.isArray(lists)) return lists;
      else if (lists) return [lists];
      return [];
    },
    selectedItems(state): FavoriteListItem[] | null {
      return state.multiselect.selectedItems;
    },
    selectedItemsAll(state): string[] | null {
      return state.multiselect.itemsForSelectAll;
    },
  },
  actions: {
    async init() {
      if (useSessionStore().isLoggedIn) await this.initloggedIn();
      else this.readOfflineViewedProducts();
    },
    async initloggedIn() {
      // init should only be called when logged in
      if (!this.initialized && useSessionStore().isLoggedIn) {
        this.readOfflineViewedProducts();
        await this.mergeViewedProductLists();
        await new Promise((resolve) => setTimeout(resolve, 100));
        await this.loadLists();
      }
    },
    async loadLists(triggerReload = true) {
      if (!useSessionStore().isLoggedIn) return;
      const site = useSiteIdent();
      try {
        const id = ++this._loadingIndex;
        const lists = await useSessionGet<FavoriteList[]>(
          `/api/${site}/user/favLists`,
        );
        if (!lists?.length) {
          return;
        }
        if (this._loadingIndex === id) this.writeLoadedFavLists(lists);
        if (triggerReload) this.triggerListReload();
      } catch (e) {
        this._loadingIndex = 0;
        handleLoadingError(e);
      }
    },
    writeLoadedFavLists(lists: FavoriteList[]) {
      const nuxt = useNuxtApp();
      this.lists = lists?.reduce(
        (acc, cur) => {
          if (acc[cur.type] && Array.isArray(acc[cur.type])) {
            (acc[cur.type] as FavoriteList[]).push(cur);
          } else if (acc[cur.type] && !Array.isArray(acc[cur.type])) {
            acc[cur.type] = [acc[cur.type] as FavoriteList, cur];
          } else {
            acc[cur.type] = cur;
          }
          return acc;
        },
        {} as State['lists'],
      );

      if (this.favoriteLists.length)
        this.lists[ListType.DEFAULT] = this.favoriteLists.sort((a, b) => {
          return a.name.localeCompare(b.name, nuxt.$languageCode, {
            numeric: true,
          });
        });

      // remove temp assigments handeled
      this._tempAssignments = this._tempAssignments.filter(
        (item) => !item.handeled,
      );
      this.initialized = true;
    },
    async loadProductsInList(): Promise<Product[]> {
      const site = useSiteIdent();

      //get first 5 products of the default favList
      const productIds =
        this.myFavoriteList?.items
          .map((item) => {
            return item.productId;
          })
          .slice(-5, this.myFavoriteList?.items.length) ?? [];

      this.favoriteLists.forEach((list) => {
        //of each other list get first five items with item.productId
        const ids = list.items
          .map((item) => {
            return item.productId;
          })
          .slice(-5, list.items.length);
        //push them to the array for the request
        ids.forEach((id) => productIds.push(id));
      });

      try {
        const products = await useSecureSessionPost<Product[]>(
          `/api/${site}/user/favList/productsOfList`,
          {
            productIds: productIds,
          },
        );
        if (products?.length) return products;
        return [];
      } catch (e) {
        handleLoadingError(e);
        return [];
      }
    },
    async mergeViewedProductLists() {
      if (!this.offlineViewdListItems.length) return;
      const site = useSiteIdent();
      const items = this.offlineViewdListItems.map((item) => {
        return {
          id: item.id,
          lastViewed: item.lastViewed,
        };
      });
      try {
        await useSecureSessionPost(
          `/api/${site}/user/favList/mergeViewedProductList`,
          {
            items,
          },
        );
        this.resetOffileViewedProducts();
      } catch (e) {
        handleLoadingError(e);
      }
    },
    async addViewedProduct(productId: string) {
      try {
        const site = useSiteIdent();
        await useSecureSessionPost(
          `/api/${site}/user/favList/addViewedProduct`,
          {
            productId,
          },
        );
      } catch (e) {
        handleLoadingError(e);
      }
    },
    isLoadingAddDelete(productId: string, listId: string): boolean {
      return !!this._tempAssignments.find(
        (item) => item.productId === productId && item.listId === listId,
      );
    },
    isFavorite(productId: string): boolean {
      //use for <3-icon in productTile, pdp, etc.
      return (
        this.isProductInMyFavorites(productId) ||
        this.isProductInFavLists(productId)
      );
    },
    isInList(productId: string, listId: string): boolean {
      //use for <3-icon in popupframe for each favList to (de)favorize a product

      const tempAssignment = this._tempAssignments.find(
        (item) => item.productId === productId && item.listId === listId,
      );
      if (tempAssignment) {
        return tempAssignment.action === 'add';
      }
      return !!this.findFavoriteListById(listId)?.items.find(
        (item) => item.productId === productId,
      );
    },
    isProductInMyFavorites(productId: string): boolean {
      return this.myFavoriteList?.items?.find(
        (product) => product.productId === productId,
      )
        ? true
        : false;
    },
    isProductInFavLists(productId: string): boolean {
      return this.favoriteLists.some((list) =>
        list.items.find((item) => item.productId === productId),
      )
        ? true
        : false;
    },
    triggerListReload() {
      this.listReloadPing = true;
      setTimeout(() => {
        this.listReloadPing = false;
      }, 1);
    },
    findFavoriteListById(listId: string): FavoriteList {
      if (listId === this.myFavoriteList?.id) return this.myFavoriteList;
      return this.favoriteLists.find((list) => list?.id === listId);
    },
    async addProductToFavList(
      productId: string,
      listId: string,
      quantity: number,
    ) {
      const site = useSiteIdent();
      //add to temp assignments to show optimistic update
      const tempAssignment: TempAssignment = {
        productId,
        listId,
        action: 'add',
        handeled: false,
      };
      this._tempAssignments.push(tempAssignment);
      const itemToAdd = [
        {
          productId: productId,
          quantity: quantity,
        },
      ];
      try {
        await useSecureSessionPost(`/api/${site}/user/favList/addItemToList`, {
          listId: listId,
          items: itemToAdd,
        });
      } catch (e) {
        handleLoadingError(e);
      }
      //remove temp assignment
      tempAssignment.handeled = true;
      this.loadLists();
    },
    async deleteProductFromFavList(productId: string, listId: string) {
      const site = useSiteIdent();
      //add to temp assignments to show optimistic update
      const tempAssignment: TempAssignment = {
        productId,
        listId,
        action: 'delete',
        handeled: false,
      };
      this._tempAssignments.push(tempAssignment);
      try {
        await useSecureSessionPost(
          `/api/${site}/user/favList/deleteProductFromList`,
          {
            listId: listId,
            productId: productId,
          },
        );
      } catch (e) {
        handleLoadingError(e);
      }

      tempAssignment.handeled = true;
      this.loadLists();
    },
    getActiveFavList(): FavoriteList {
      const favListUrlParam = String(useRoute().params?.id);
      if (String(useRoute().path) === '/productlists/favorites') {
        return this.myFavoriteList;
      } else {
        return this.favoriteLists.find((list) => list?.id === favListUrlParam);
      }
    },
    async editQuantiyOfItem(listId: string, itemId: string, quantity: number) {
      const site = useSiteIdent();
      try {
        await useSecureSessionPost(
          `/api/${site}/user/favList/editQuantityOfItem`,
          {
            listId,
            itemId,
            quantity,
          },
        );
      } catch (e) {
        handleLoadingError(e);
      }

      this.loadLists();
    },
    // offline Viewed Product List Actions
    addOfflineViewedProduct(productId: string) {
      if (!import.meta.client) {
        return;
      }
      const viewedList = window.localStorage.getItem(
        offlineViewedListStorageKey,
      );
      let newList: { products: { id: string; lastViewed: string }[] };
      if (viewedList) {
        const parsedList: { products: { id: string; lastViewed: string }[] } =
          JSON.parse(viewedList);
        if (parsedList.products.find(({ id }) => id === productId)) {
          parsedList.products.find(({ id }) => id === productId).lastViewed =
            new Date().toISOString();
          return;
        }
        parsedList.products.push({
          id: productId,
          lastViewed: new Date().toISOString(),
        });
        newList = parsedList;
      } else {
        newList = {
          products: [
            {
              id: productId,
              lastViewed: new Date().toISOString(),
            },
          ],
        };
      }

      while (newList.products.length > 48) {
        const oldestProduct = newList.products.reduce((acc, cur) => {
          if (acc.lastViewed < cur.lastViewed) {
            return acc;
          } else {
            return cur;
          }
        });
        const index = newList.products.indexOf(oldestProduct);
        newList.products.splice(index, 1);
      }

      window.localStorage.setItem(
        offlineViewedListStorageKey,
        JSON.stringify(newList),
      );
      setTimeout(() => {
        this.readOfflineViewedProducts();
      }, 0);
    },
    readOfflineViewedProducts() {
      if (!import.meta.client) {
        return;
      }
      const viewedList = window.localStorage.getItem(
        offlineViewedListStorageKey,
      );
      if (viewedList) {
        const parsedList: { products: { id: string; lastViewed: string }[] } =
          JSON.parse(viewedList);
        this.offlineViewdListItems = parsedList.products;
      } else this.offlineViewdListItems = [];
      setTimeout(() => {
        this.triggerListReload();
      }, 100);

      this.offlineInitialized = true;
    },
    resetOffileViewedProducts() {
      if (!import.meta.client) {
        return;
      }
      window.localStorage.removeItem(offlineViewedListStorageKey);
      this.offlineViewdListItems = [];
    },
    // multiselect actions
    initMultiselect(listId: string) {
      this.multiselect.listId = listId;
      this.multiselect.selectedItems = [];
      this.multiselect.showMultiSelect = true;
      this.multiselect.showSuccessBannerDelete = false;
      this.multiselect.showSuccessBannerMove = false;
    },
    toggleListItemSelection(itemId: string, selectAll = false) {
      if (!this.multiselect.listId) return;

      const selectedItem = this.multiselect.selectedItems.find(
        (item) => item?.id === itemId,
      );

      if (selectedItem) {
        if (selectAll) {
          return;
        }
        this.multiselect.selectedItems = this.multiselect.selectedItems.filter(
          (item) => item?.id !== itemId,
        );
      } else {
        //push the whole item
        this.multiselect.selectedItems.push(
          this.getActiveFavList().items.find((item) => item?.id === itemId),
        );
      }
    },
    selectAllItems() {
      this.multiselect.itemsForSelectAll.forEach((itemId) => {
        this.toggleListItemSelection(itemId, true);
      });
    },
    isItemSelected(itemId: string): boolean {
      return this.multiselect.selectedItems.find((item) => item?.id === itemId)
        ? true
        : false;
    },
    resetMultiselect(successOnDelete = false, successOnMove = false) {
      this.multiselect.listId = null;
      this.multiselect.selectedItems = [];
      this.multiselect.showMultiSelect = false;
      this.multiselect.showSuccessBannerDelete = successOnDelete;
      this.multiselect.showSuccessBannerMove = successOnMove;
    },
    async moveSelectedItems(toListId: string) {
      const list = this.findFavoriteListById(this.multiselect.listId);

      const result = await this.moveItems(
        list.id,
        toListId,
        this.multiselect.selectedItems,
      );
      if (result) {
        this.temp_multiselect = { ...this.multiselect };
        this.temp_multiselect.moveToList = this.findFavoriteListById(toListId);
        this.resetMultiselect(false, true);
        setTimeout(() => {
          this.temp_multiselect = null;
        }, 10000);
      }
      this.loadLists();
      return result;
    },
    async deleteSelectedItems() {
      const list = this.findFavoriteListById(this.multiselect.listId);
      const result = await this.deleteItemsFromList(
        list.id,
        this.multiselect.selectedItems,
      );
      if (result) {
        this.temp_multiselect = { ...this.multiselect };
        this.resetMultiselect(true);
        setTimeout(() => {
          this.temp_multiselect = null;
        }, 10000);
      }
      this.loadLists();
      return result;
    },
    async restoreDeletedItems() {
      if (!this.temp_multiselect) return;
      const list = this.findFavoriteListById(this.temp_multiselect.listId);

      const result = await this.addItemsToList(
        list.id,
        this.temp_multiselect.selectedItems,
      );
      if (result) {
        this.temp_multiselect = null;
      }
      this.loadLists();
      return result;
    },
    async deleteItemsFromList(listId: string, items: FavoriteListItem[]) {
      const site = useSiteIdent();
      try {
        return await useSecureSessionPost(
          `/api/${site}/user/favList/deleteItems`,
          {
            listId: listId,
            items: items,
          },
        );
      } catch (e) {
        handleLoadingError(e);
      }
    },
    async addItemsToList(listId: string, items: AddItemsBody['items']) {
      const site = useSiteIdent();
      try {
        return await useSecureSessionPost(
          `/api/${site}/user/favList/addItems`,
          {
            listId: listId,
            items: items,
          },
        );
      } catch (e) {
        handleLoadingError(e);
      }
    },
    async moveItems(
      sourceListId: string,
      targetListId: string,
      items: FavoriteListItem[],
    ) {
      const site = useSiteIdent();
      try {
        return await useSecureSessionPost(
          `/api/${site}/user/favList/moveItems`,
          {
            sourceListId: sourceListId,
            targetListId: targetListId,
            items: items,
          },
        );
      } catch (e) {
        handleLoadingError(e);
      }
    },
  },
});
