import StoreIds from './storeIds';
import {
  useSecureSessionPost,
  useSessionGet,
} from '~/composables/dataFetching/genericFetchers';
import type { Result as UserData } from '~/server/api/[site]/user/data/minimal.get';
import type { Result as AccountData } from '~/server/api/[site]/user/data/account.get';
import type { SessionContext } from '~/server/api/[site]/user/context.get';
import type { Body as ChangeContextBody } from '~/server/api/[site]/user/context.post';
import { useUserAddress } from '@/stores/useUserAddress';
import { handleLoadingError } from '~/utils/handleLoadingError';
import { useSessionStore } from './useSessionStore';

interface State {
  userData?: UserData;
  accountData?: AccountData;
  initialized: boolean;
  userContext?: SessionContext;
  isLoading: boolean;
}

export const useUserContext = defineStore(StoreIds.USER_CONTEXT, {
  state: () =>
    ({
      userData: null,
      accountData: null,
      initialized: false,
      userContext: null,
      isLoading: false,
    }) as State,
  actions: {
    async init() {
      if (this.initialized) return;
      this.loadUserData();
      this.loadAccountData();
      this.loadUserContext();
    },
    async loadUserData(refresh = false) {
      if (!refresh && this.userData) return this.userData;
      try {
        const site = useSiteIdent();
        const result = await useSessionGet(`/api/${site}/user/data/minimal`);

        if (result?.customerId) this.userData = result || null;
        else this.userData = null;

        if (!this.userData && useSessionStore().isLoggedIn) {
          useSessionStore().logout({ wasAutoLogout: true });
        }

        this.initialized = true;
      } catch (e: any) {
        this.initialized = false;
        this.userData = null;
        handleLoadingError(e);
      }
      return this.userData;
    },
    async loadAccountData(refresh = false): Promise<AccountData | null> {
      if (!refresh && this.accountData) return this.accountData;
      const site = useSiteIdent();
      try {
        const result = await useSessionGet(`/api/${site}/user/data/account`);
        if (result?.customerNumber) this.accountData = result || null;
      } catch (e: any) {
        if (![401, 403].includes(e.statusCode)) {
          handleLoadingError(e);
        }
        this.accountData = null;
      }
      return this.accountData;
    },
    async loadAddresses(refresh = false) {
      await useUserAddress().loadAddresses(refresh);
      return useUserAddress().addresses;
    },
    async loadUserContext(refresh = false) {
      if (!refresh && this.userContext) return this.userContext;
      try {
        const result = await useSessionGet<SessionContext>(
          `/api/${useSiteIdent()}/user/context`,
        );
        this.userContext = result;
      } catch (e: any) {
        if (![401, 403].includes(e.statusCode)) {
          handleLoadingError(e);
        }
        this.userContext = null;
      }
      return this.userContext;
    },
    async changeUserContext(context: Partial<ChangeContextBody>) {
      try {
        await useSecureSessionPost(
          `/api/${useSiteIdent()}/user/context`,
          context,
        );
        return this.loadUserContext(true);
      } catch (e) {
        handleLoadingError(e);
      }
    },
    async setDefaultBillingAddress(addressId: string) {
      try {
        this.isLoading = true;
        await useSecureSessionPost(
          `/api/${useSiteIdent()}/user/account/setDefaultBillingAddress`,
          { addressId },
        );
        await this.loadAddresses(true);
      } catch (e) {
        handleLoadingError(e);
      } finally {
        this.isLoading = false;
      }
    },
    async setDefaultShippingAddress(addressId: string) {
      this.isLoading = true;
      try {
        await useSecureSessionPost(
          `/api/${useSiteIdent()}/user/account/setDefaultShippingAddress`,
          { addressId },
        );
        await this.loadAddresses(true);
      } catch (e) {
        handleLoadingError(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
  getters: {
    activeShippingAddressId(): string {
      return this.userContext?.activeShippingAddressId;
    },
    activeBillingAddressId(): string {
      return this.userContext?.activeBillingAddressId;
    },
    canEdit(): boolean {
      return this.accountData?.canEdit;
    },
    maskedBankData(state) {
      if (state.accountData?.bankData?.iban) {
        return state.accountData?.bankData;
      }
      return null;
    },
    customerTypeId(): string {
      return this.accountData?.customerType.id ?? '0';
    },
  },
});
