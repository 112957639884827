<template>
  <NuxtErrorBoundary v-if="type !== 'stub'">
    <component :is="getDebugComponent(type)" :data="data" />
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
import { useClientDebugEnabled } from './useGetDebugData';

defineProps({
  type: {
    type: String as PropType<keyof typeof debugComponents>,
    required: true,
  },
  data: {
    type: Object as any,
    required: false,
    default: null,
  },
});

/**
 * The Idea for later:
 * On Prod-Build replace the content of all Debug components with the content of the Stub-Component
 * So any Debug code will be excluded from a Prod-build
 */

const debugComponents = {
  stub: defineAsyncComponent(() => import('./components/stub.vue')),
  order_createDelivery: defineAsyncComponent(
    () => import('./components/createDeliveryForOrder.client.vue'),
  ),
  order_createCreditMemo: defineAsyncComponent(
    () => import('./components/createCreditMemoForOrder.vue'),
  ),
  product_orderAlarm: defineAsyncComponent(
    () => import('./components/stub.vue'),
  ),
  sentry_replay: defineAsyncComponent(
    () => import('./components/sentryReplay.client.vue'),
  ),
  subscription_updateNextOrderDate: defineAsyncComponent(
    () => import('./components/updateSubscriptionNextOrderDate.vue'),
  ),
};

function getDebugComponent(type: keyof typeof debugComponents) {
  if (!useClientDebugEnabled()) {
    return debugComponents['stub'];
  } else {
    return debugComponents[type] ?? debugComponents['stub'];
  }
}
</script>
