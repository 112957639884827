export default function useTheme() {
  const nuxtApp = useNuxtApp();
  useHead({
    link: [
      {
        id: 'themeLink',
        rel: 'stylesheet',
        href: nuxtApp.$themeFilePath,
      },
    ],
  });
}
