import { UseStateKeys } from '@/useStateKeys';
import { useSessionGet } from '~/composables/dataFetching/genericFetchers';
import type { ShippingMethod } from '@/server/gateway/connections/shopware/loaders/loadShippingMethods';
import { handleLoadingError } from '~/utils/handleLoadingError';

export default async function useAvailableShippingMethods(refresh = false) {
  const shippingMethodsStore = useState<ShippingMethod[]>(
    UseStateKeys.SHIPPING_METHODS,
    () => [],
  );
  if (shippingMethodsStore.value.length && refresh === false) {
    return shippingMethodsStore;
  }

  try {
    const shippingMethods = await useSessionGet<ShippingMethod[]>(
      `/api/${useSiteIdent()}/shop/shipping/methods`,
    );
    if (shippingMethods?.length) shippingMethodsStore.value = shippingMethods;
  } catch (e) {
    handleLoadingError(e);
  }

  return shippingMethodsStore;
}
