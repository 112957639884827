import { useSessionStore } from '~/stores/useSessionStore';

const authRoutes = ['/account/changePassword'];

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server || !useShopEnabled()) return;
  const session = useSessionStore();

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(
      'loginStatus: ',
      session.isLoggedIn,
      ' sessionId: ',
      session.sessionId,
    );
  }

  if (!session.isLoggedIn && authRoutes.includes(to.path)) {
    return navigateTo({ path: '/login' });
  }
  return;
});
