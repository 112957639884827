import type { Translations } from '~/server/gateway/connections/translation/getLoader';
import { createI18n } from 'vue-i18n';

const numberFormats = {
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    currencyNoCents: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
  nl: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    currencyNoCents: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
  },
};

export function useInitTranslations(translations: Translations) {
  const nuxtApp = useNuxtApp();
  const languageCode = nuxtApp.$languageCode;

  const i18n = createI18n({
    fallbackLocale: 'de',
    locale: languageCode,
    legacy: false,
    globalInjection: true,
    numberFormats,
    messages: {
      [languageCode]: translations,
    },
  });
  nuxtApp.vueApp.use(i18n);
}
