import type { SystemContext } from '~/server/api/[site]/core/[...uri].get';
import StoreIds from './storeIds';

interface State {
  context: SystemContext;
}

export const useSystemContext = defineStore(StoreIds.SYSTEM_CONTEXT, {
  state: () =>
    ({
      context: null,
    }) as State,
  actions: {
    async init(context: SystemContext) {
      this.context = context;
    },
  },
  getters: {
    shop: (state) => state.context?.shop,
  },
});
