export enum BannerPositions {
  SALES_BAR = 'salesbar',
  FOOTER = 'footer',
  STARTPAGE_TOPBANNER = 'startpage_topbanner',
  STARTPAGE_POSITION_2 = 'startpage_position2',
  STARTPAGE_POSITION_3 = 'startpage_position3',
  STARTPAGE_POSITION_4 = 'startpage_position4',
  STARTPAGE_POSITION_5 = 'startpage_position5',
  STARTPAGE_POSITION_6 = 'startpage_position6',
  CONTENTPAGE_H1 = 'contentpage_h1',
  CATEGORYPAGE_BELOW_LIST = 'categorypage_below_list',
  PRODUCTLIST_BELOW_LIST = 'productlist_below_list',
  NAV_FLYOUT = 'navFlyout',
  NOTIFICATION = 'notification',
  WIDGET_NOTIFICATION = 'widgetNotification',
  DEALS_OF_THE_WEEK = 'dealsOfTheWeek',
  PDP_ACTION_TEXT = 'pdpActionText',
  PRODUCT_RECOMMENDATION = 'productRecommendation',
}

export const globalBannerPositions = [
  BannerPositions.SALES_BAR,
  BannerPositions.FOOTER,
  BannerPositions.NAV_FLYOUT,
  BannerPositions.NOTIFICATION,
  BannerPositions.WIDGET_NOTIFICATION,
];

export const startPageBannerPositions = [
  BannerPositions.STARTPAGE_TOPBANNER,
  BannerPositions.DEALS_OF_THE_WEEK,
  BannerPositions.STARTPAGE_POSITION_2,
  BannerPositions.STARTPAGE_POSITION_3,
  BannerPositions.STARTPAGE_POSITION_4,
  BannerPositions.STARTPAGE_POSITION_5,
  BannerPositions.STARTPAGE_POSITION_6,
];
