import { useSecureSessionPost } from '@/composables/dataFetching/genericFetchers';
import type { PostBody } from '@/server/api/[site]/user/account/bankData.post';
import type { BankData } from '@/components/shop/checkout/payment/types';
import useValidateBankData from '@/composables/payment/useValidateBankData';
import { useUserContext } from '@/stores/useUserContext';
import { handleLoadingError } from '~/utils/handleLoadingError';

export default async function useSubmitBankData(bankData: BankData) {
  const validation = useValidateBankData(
    bankData.bankName,
    bankData.iban,
    bankData.bic,
  );
  if (!validation.allOK) {
    throw new Error('Bankdata is not valid');
  }

  try {
    // @TODO: Transform Bankdata to body
    const body: PostBody = {
      iban: bankData.iban.replace(/ /g, ''),
      bic: bankData.bic,
      bankName: bankData.bankName,
      collectiveInvoice: bankData.collectiveInvoice,
    };
    await useSecureSessionPost(
      `/api/${useSiteIdent()}/user/account/bankData`,
      body,
    );
    await useUserContext().loadAccountData(true);
    return true;
  } catch (e) {
    handleLoadingError(e);
    throw e;
  }
}
