/* eslint-disable no-console */
export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  if (import.meta.client && config.public.DEBUGMODE === '1') {
    //Enables performance tracking in browser :)
    nuxtApp.vueApp.config.performance = true;
  }

  if (
    import.meta.server &&
    process.env.NODE_ENV === 'development' &&
    config.public.DEBUGMODE === '1'
  ) {
    let appCreatedTime = Date.now();
    let pageRenderedTime = Date.now();
    let vueSetupTime = Date.now();
    //let pageStartTime = Date.now();
    //let pageFinishTime = Date.now();

    nuxtApp.hook('app:created', () => {
      appCreatedTime = Date.now();
    });
    nuxtApp.hook('vue:setup', () => {
      vueSetupTime = Date.now();
    });
    // I dont get this fully yet... :(
    //nuxtApp.hook('page:start', () => {
    //  pageStartTime = Date.now();
    //});
    //nuxtApp.hook('page:finish', () => {
    //  pageFinishTime = Date.now();
    //  console.debug(
    //    `[DEBUG:::][Rendering] - url: ${
    //      nuxtApp.ssrContext.url
    //    } | Page Start to Finish: ${pageFinishTime - pageStartTime}ms`,
    //  );
    //});

    nuxtApp.hook('app:rendered', () => {
      pageRenderedTime = Date.now();
      console.debug(
        `[DEBUG]:[Rendering] App to SSR-Done: ${
          pageRenderedTime - appCreatedTime
        }ms | url: ${nuxtApp.ssrContext.url} |`,
      );
      console.debug(
        `[DEBUG]:[Rendering] Setup to SSR-Done: ${
          pageRenderedTime - vueSetupTime
        }ms | url: ${nuxtApp.ssrContext.url} |`,
      );
    });
  }
});
