import { useLogContextAction } from './actions/useLogContextAction';
import { useLoginAction } from './actions/useLoginAction';
import { useSubscriptionEditAction } from './actions/useSubscriptionEditAction';
import { useSubscriptionPauseAction } from './actions/useSubscriptionPauseAction';

/**
 * Action keys that can be used in the URL
 * the value of the key is used in the url query
 * e.g. ?actions=login,logcontext
 */
export enum ActionKeys {
  LOGIN = 'login',
  LOG_CONTEXT = 'logcontext',
  SUBSCRIPTION_EDIT = 'subscriptionedit',
  SUBSCRIPTION_PAUSE = 'subscriptionpause',
}

export type UrlActionsContext = Record<ActionKeys, Record<string, any>>;

/**
 * An action handler, that can take the return value of the previous action
 */
export type UrlActionHandler = (
  context?: UrlActionsContext,
) => Promise<Record<string, any>>;

const actionMap: Record<ActionKeys, UrlActionHandler> = {
  /**
   * Opens the login dialog if the user is not logged in
   * @returns { isLoggedIn: boolean }
   */
  [ActionKeys.LOGIN]: useLoginAction,
  /**
   * logs the current context. use for debug purposes
   */
  [ActionKeys.LOG_CONTEXT]: useLogContextAction,
  /**
   * Opens the subscription edit dialog
   */
  [ActionKeys.SUBSCRIPTION_EDIT]: useSubscriptionEditAction,
  /**
   * Opens the subscription pause dialog to cancel a subscription
   */
  [ActionKeys.SUBSCRIPTION_PAUSE]: useSubscriptionPauseAction,
};

export async function useUrlActions() {
  const query = new URLSearchParams(window.location.search);
  const rawActions = query.get('actions');
  if (rawActions && !import.meta.server) {
    const actions = rawActions.split(',');
    const context = {} as UrlActionsContext;
    for (const action of actions) {
      if (actionMap[action as ActionKeys]) {
        const current = await actionMap[action as ActionKeys](context);
        if (current) context[action as ActionKeys] = current;
      }
    }
  }
}
