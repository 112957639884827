import { DialogIdent, useDialogStore } from '@/stores/useDialogStore';
import { DialogResponses } from '~/stores/useDialogStore';

export default function useDrugContactAddressChoiceDialog() {
  const dialogStore = useDialogStore();
  return {
    open: async () => {
      return dialogStore.openDialog(DialogIdent.DRUG_CONTACT_ADDRESS_CHOICE);
    },
    getData: () => {
      return dialogStore.getDialogData<any>(
        DialogIdent.DRUG_CONTACT_ADDRESS_CHOICE,
      );
    },
    close: (data?: any) => {
      dialogStore.closeDialog(data ?? DialogResponses.SUCCESS);
    },
    cancel: () => {
      dialogStore.closeDialog(DialogResponses.CANCEL);
    },
  };
}
