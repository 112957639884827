import { useCheckoutStore, CheckoutSteps } from '@/stores/useCheckout';
import type { BankData } from '@/components/shop/checkout/payment/types';
import { UseStateKeys } from '@/useStateKeys';
import { scrollToElement } from '@/helpers/scroll';
import { useUserContext } from '@/stores/useUserContext';
import { PaymentMethodId } from '@/@types/paymentMethodIds';

export default async function useRegisterBankDataValidationEvent() {
  await useCheckoutStore().registerEvent(
    CheckoutSteps.PAYMENT_SHIPPING,
    async () => {
      const bankDataInput = useState<BankData>(
        UseStateKeys.BANKDATA_INPUT_CHECKOUT,
      );

      if (
        useUserContext().userContext?.selectedPaymentMethod.id !==
        PaymentMethodId.DEBIT
      ) {
        bankDataInput.value = {
          bankName: '',
          iban: '',
          bic: '',
          error: ['BANKDATA_INCOMPLETE'],
        };
      }

      if (
        !useUserContext().maskedBankData &&
        useUserContext().userContext?.selectedPaymentMethod.id ===
          PaymentMethodId.DEBIT &&
        bankDataInput.value?.error.length > 0
      ) {
        bankDataInput.value.error = ['BANKDATA_INVALID'];
        scrollToElement(document.getElementById('PaymentMethodSelect'));
      }
      return true;
    },
    {
      id: 'BankDataValidation_Hint',
      type: 'ON_NEXT',
      runEveryTime: true,
    },
  );
}
