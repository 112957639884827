export default function useGetDebugData() {
  if (!useClientDebugEnabled() || !import.meta.client) return null;

  const debugData = useLocalStorage(
    `debug-manager-${useSiteIdent()}`,
    getDefaultValues(),
  );

  migrateVersion(debugData, getDefaultValues());

  return debugData;
}

function getDefaultValues() {
  return {
    version: 1,
    hide: false,
    showDebug: false,
    shopware: {
      client_id: '',
      client_secret: '',
    },
    app: {
      deliveryCreation: false,
      productSubscription: false,
      creditMemoCreation: false,
    },
    sentry: {
      overlay: false,
    },
    store: {
      debug: false,
    },
    apiToken: '',
  };
}

function migrateVersion(
  ovData: Ref<Record<string, any>>,
  nvDataDefaults: Record<string, any>,
) {
  if (ovData.value.version < nvDataDefaults.version) {
    //Try to keep fields populated at some point
    //Check Objekt-Keys recursively, remove if not in new version, add if not in old version
    ovData.value = nvDataDefaults as any;
  }
}

export function useClientDebugEnabled() {
  const isLive = useRuntimeConfig()
    .public.ENVIRONMENT_NAME.toLowerCase()
    .startsWith('prod');
  const debugMode = useRuntimeConfig().public.CLIENT_DEBUGMODE;
  const debugEnabled =
    typeof debugMode === 'number' ? debugMode === 1 : debugMode === '1';

  return !isLive && debugEnabled;
}
