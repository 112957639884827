import { DialogIdent, useDialogStore } from '~/stores/useDialogStore';
import { useRedirectData } from '@/stores/useRedirectData';

export async function useSubscriptionPauseAction() {
  const dialogStore = useDialogStore();
  const redirect = useRedirectData();
  // waiting for subscription data to be set on the pdp
  await new Promise(() => {
    setTimeout(async function () {
      const subscriptionData =
        redirect.getAndRemoveData<any>('subscriptionData');
      if (subscriptionData?.data) {
        await dialogStore.openDialog(DialogIdent.SUBSCRIPTION_PAUSE, {
          subscription: subscriptionData.data.subscription,
          product: subscriptionData.data.product,
          triggerReload: true,
        });
        subscriptionData.data.reload = true;
      }
    }, 2500);
  });
  return { triggerReload: true };
}
