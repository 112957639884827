import { SiteIdent } from './types';
/**
 * @TODO:
 * Very nice to have:
 * Would be nice if we could simply have a Script that reads the CMS Files and generates this file automatically
 * This would make the process of adding or editing sites coupled to the CMS Files, so it's less error prone
 */
export const hostMap: Record<
  string,
  {
    subdomain: 'vdv' | 'minilu';
    languageCode: 'de' | 'at' | 'nl';
    localeCode: 'de-DE' | 'de-AT' | 'nl-NL';
    siteIdent: SiteIdent;
    siteId: number;
  }
> = {
  'vanderven.de': {
    subdomain: 'vdv',
    languageCode: 'de',
    localeCode: 'de-DE',
    siteIdent: SiteIdent.default,
    siteId: 1,
  },
  'minilu.de': {
    subdomain: 'minilu',
    languageCode: 'de',
    localeCode: 'de-DE',
    siteIdent: SiteIdent.miniluDe,
    siteId: 3,
  },
  'minilu.at': {
    subdomain: 'minilu',
    languageCode: 'at',
    localeCode: 'de-AT',
    siteIdent: SiteIdent.miniluAt,
    siteId: 4,
  },
  'minilu.nl': {
    subdomain: 'minilu',
    languageCode: 'nl',
    localeCode: 'nl-NL',
    siteIdent: SiteIdent.miniluNl,
    siteId: 5,
  },
};

export default function getSiteByHost(hostname: string) {
  const hosts = Object.keys(hostMap);

  const hostIdent = hosts.find((h) => hostname?.includes(h)) || hosts[0];
  return hostMap[hostIdent];
}

export function getHostBySite(site: SiteIdent) {
  const host = Object.entries(hostMap).find(([, v]) => v.siteIdent === site);
  return host?.[0];
}
