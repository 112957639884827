import { useCheckoutStore, CheckoutSteps } from '@/stores/useCheckout';
import { useUserContext } from '@/stores/useUserContext';
import { useUserAddress } from '@/stores/useUserAddress';

export default async function useRegisterCheckBillingAddressOnNewUserEvent() {
  const userContext = useUserContext();

  if (userContext.initialized && !userContext.canEdit) return;
  for (const step of Object.values(CheckoutSteps)) {
    if (step === CheckoutSteps.THANKYOU || step === CheckoutSteps.LOGIN) {
      continue;
    }

    await useCheckoutStore().registerEvent(
      step,
      async () => {
        try {
          if (
            userContext.canEdit &&
            !useUserAddress().billingAddressIsContactAddress
          ) {
            await useUserAddress().setActiveBillingAddressToContactAddress();
          }
          return true;
        } catch {
          return false;
        }
      },
      {
        id: step + '_billingAddressCheck',
        type: 'ON_ENTER',
        runEveryTime: true,
      },
    );
  }
}
