import StoreIds from './storeIds';

export enum DialogIdent {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  AUTO_LOGOUT = 'auto-logout',
  CHANGE_PASSWORD = 'change-password',
  SEND_FORGOT_PASSWORD_EMAIL = 'send-forgot-password-email',
  RESET_LEGACY_PASSWORD = 'reset-legacy-password',
  EDIT_PROFILE = 'edit-profile',
  NEW_EDIT_ADDRESS = 'new-edit-address',
  DELETE_ADDRESS = 'delete-address',
  UPLOAD_VERIFICATION_FILES = 'upload-verification-files',
  DELETE_VERIFICATION_FILE = 'delete-verification-file',
  ORDER_DETAILS = 'orderDetails',
  ORDER_ALARM_STATUS = 'orderAlarmStatus',
  NEW_EDIT_FAVORITE_LIST = 'new-edit-favorite-list',
  DELETE_FAVORITE_LIST = 'delete-favorite-list',
  NEW_EDIT_BANKDATA = 'new-edit-bankdata',
  DELETE_BANKDATA = 'delete-bankdata',
  FAV_LIST_MULTI_SELECT_MOVE_ITEMS = 'multi-select-move-items',
  FAV_LIST_MULTI_SELECT_DELETE = 'multi-select-delete',
  DRUG_CONTACT_ADDRESS_CHOICE = 'drug-contact-address-choice',
  EDIT_CAMPAIGN = 'edit-campaign',
  SUBSCRIPTION_DELIVERY_NOW = 'subscription-delivery-now',
  SUBSCRIPTION_PAUSE = 'subscription-pause',
  SUBSCRIPTION_EDIT = 'subscription-edit',
  SUBSCRIPTION_CANCEL = 'subscription-cancel',
  SUBSCRIPTION_REACTIVATE = 'subscription-reactivate',
  SUBSCRIPTION_ACTIVATE = 'subscription-activate',
  RETURN_CLAIM_CHOICE = 'return-claim-choice',
  RETURN_ITEMS_EXCLUSION_REASON = 'return-items-exclusion-reason',
  ACTION_PAGE = 'action-page',
  PRODUCT_REQUEST = 'product-request',
  COMPLAINT_CONTACT_DATA = 'complaint-contact-data',
  COLLECTIVE_INVOICE = 'collective-invoice',
  VERIFICATION_FILE_UPLOAD_SUCCESS = 'verification-file-upload-success',
  LOADING = 'loading',
}

export enum DialogResponses {
  SUCCESS = 'success',
  CANCEL = 'cancel',
}

interface State {
  open: boolean;
  dialogIdent: DialogIdent | string | null;
  dialogData?: Partial<Record<DialogIdent | string, Record<string, any>>>;
  headline?: string;
}

let resolveDialogPromise: null | ((value: any) => void) = null;

/**
 * @info
 * Specific Dialog-Wrappers with more Typesafety can be found under composbales/dialogs
 * @action openDialog -
 * Opens a dialog and returns a promise that resolves when the dialog is closed.
 * Await this and pass Data in Close Dialog if needed (e.g. when you want a result from the dialog)
 * @action closeDialog -
 * Closes the currenlty open dialog, independent of ID, them resolves the promise of openDialog.
 * Pass data you want to return when awaiting openDialog here.
 *
 */
export const useDialogStore = defineStore(StoreIds.DIALOG, {
  state: () =>
    ({
      open: false,
      dialogIdent: null,
      dialogData: {},
      headline: '',
    }) as State,
  actions: {
    openDialog<T = any>(
      dialogIdent: DialogIdent | string,
      data?: Record<string, any>,
    ): Promise<T> {
      if (this.open) this.closeDialog(DialogResponses.CANCEL);

      this.headline = '';
      this.open = true;
      this.dialogIdent = dialogIdent;
      this.setDialogData(data);
      return new Promise<T>((resolve) => {
        resolveDialogPromise = resolve;
      });
    },
    closeDialog(data?: any) {
      if (!this.open) return;

      this.open = false;
      this.dialogIdent = null;
      this.headline = '';
      resolveDialogPromise(data);
      resolveDialogPromise = null;
    },
    setDialogData(data: Record<string, any>, ident?: DialogIdent | string) {
      if (this.dialogIdent) {
        this.dialogData[this.dialogIdent] = data;
      } else if (ident) {
        this.dialogData[ident] = data;
      }
    },
    getDialogData<T = Record<string, any>>(ident?: DialogIdent | string): T {
      if (this.dialogIdent && this.dialogData[this.dialogIdent]) {
        return this.dialogData[this.dialogIdent] as T;
      } else if (ident && this.dialogData[ident]) {
        return this.dialogData[ident] as T;
      }
    },
    setHeadline(headline: string) {
      if (this.open) {
        this.headline = headline;
      }
    },
  },
});
