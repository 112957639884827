export enum BasketQuantityValidationState {
  MIN_PURCHASE_CART = 'minPurchaseCart',
  MIN_PURCHASE_FINDER = 'minPurchaseFinder',
  MAX_PURCHASE_CART = 'maxPurchaseCart',
  MAX_PURCHASE_FINDER = 'maxPurchaseFinder',
  STEP_SIZE_CART = 'stepSizeCart',
  STEP_SIZE_FINDER = 'stepSizeFinder',
}

export enum BasketActionSource {
  CART = 'CART',
  FINDER = 'FINDER',
}
