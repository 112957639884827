import { useCartStore } from '~/stores/useCart';
import { useCheckoutStore } from '~/stores/useCheckout';
import { useFavLists } from '~/stores/useFavLists';
import { useSessionStore } from '~/stores/useSessionStore';
import { useUserAddress } from '~/stores/useUserAddress';
import { useUserContext } from '~/stores/useUserContext';
import { CheckoutSteps } from '@/stores/useCheckout';

/**
 * This composable is used to initialize the stores that need initializiation.
 * it also sets up interation beteween stores, like the cart and the session store.
 */
export async function useInitStores() {
  const shopIsEnabled = useShopEnabled();

  if (shopIsEnabled) {
    const session = useSessionStore();
    const userContext = useUserContext();
    const userAddress = useUserAddress();
    const cart = useCartStore();
    const favLists = useFavLists();
    if (import.meta.client) session.init();
    onMounted(async () => {
      // add things to happen on login
      session.registerOnLogin(async () => favLists.initloggedIn());
      session.registerOnLogin(async () => userContext.init());
      session.registerOnLogin(async () => {
        if (
          useRoute().path.includes(CheckoutSteps.LOGIN) &&
          useSessionStore().isLoggedIn
        ) {
          await useCheckoutStore().init({
            refresh: true,
            withAfterLoginCartLoad: true,
          });
          await useCheckoutStore().goToStep(
            useCheckoutStore().nextAvailableStep,
          );
        } else {
          await cart.loadCartAfterLogin();
        }
      });

      // add things to happen on logout
      session.registerOnLogout(async () => cart.loadCart());
      session.registerOnLogout(async () => {
        favLists.$reset();
        favLists.readOfflineViewedProducts();
      });
      session.registerOnLogout(async () => userContext.$reset());
      session.registerOnLogout(async () => userAddress.reset());
      session.registerOnLogout(async () =>
        favLists.resetOffileViewedProducts(),
      );
      session.registerOnLogout(async () => useCheckoutStore().$reset);
      session.registerOnLogin(async () => {
        const hasBeenShownInCheckout = useCookie<boolean>(
          'VerificationHint_Checkout_AlreadyShown',
        );
        hasBeenShownInCheckout.value = false;
      });

      // init stores
      session.init();
      if (session.isLoggedIn) {
        const isLoggedIn = await userContext.loadUserData(true);
        if (isLoggedIn) {
          await session.execLoginCbs();
        }
      } else {
        await session.execLogoutCbs();
      }

      //Refresh data when the active Browser Tab changes or user switches to another window and comes back to this tab later
      window.addEventListener('focus', async () => {
        const sessionId = useSessionStore().sessionId;
        const wasLoggedIn = useSessionStore().isLoggedIn;
        session.init();
        if (session.isLoggedIn) {
          if (wasLoggedIn && sessionId !== useSessionStore().sessionId) {
            await session.execLogoutCbs();
            await navigateTo('/');
          }
          const isLoggedIn = await userContext.loadUserData(true);
          if (isLoggedIn) {
            userContext.loadAccountData();
            userContext.loadUserContext();
            favLists.loadLists();
          }
        } else {
          favLists.readOfflineViewedProducts();
        }
        cart.loadCart();
        if (
          useCheckoutStore().storesInitialized &&
          !useCheckoutStore().isLoading &&
          useCheckoutStore().initialized
        ) {
          useCheckoutStore().init({ refresh: true });
        }
      });
    });
  }
}
