import { type _GettersTree, defineStore } from 'pinia';
import type { EFormType } from '@/@types/formtype';
import StoreIds from './storeIds';
import type { AuxiliaryServiceData } from '../server/transformers/EntityTransformers/serviceTransformer';
import type { BasePageData } from '../server/transformers/page/basePage';
import { useAreaStore } from '~/stores/useArea';
import type { Order } from '~/server/transformers/shop/order/types';
import type { ReturnCreateResponse } from '~/server/gateway/connections/shopware/actions/return/create';
import type { Result as ComplaintCreateResponse } from '~/server/api/[site]/shop/complaint/create.post';

/**
 * @EventDate
 * This is NOT bound to Event-Pages
 * Ment as a generic date for "something" that happens here (Veranstaltung, Training, Führung...anything, really)
 */
type Reservation = {
  timestamp: Date;
  typeHandle: string;
  formSubmissionData?: any;
  formDataRaw?: any;
  pageDataRaw?: BasePageData;
  type?: EFormType;
  entryDate?: Date;
  price?: {
    full?: number;
    gross?: number;
    shipping?: number;
    vat?: number;
    perPerson?: number;
    service?: number;
  };
  hasAdditionalServiceSelected?: boolean;
  expectedAttendeesCount?: number;
  tags?: string[];
  auxiliaryService?: AuxiliaryServiceData | null;
  shopOrder?: Order | any;
  shopReturn?: ReturnCreationData | any;
  shopComplaint?: ComplaintCreationData | any;
};

interface State {
  reservations: Reservation[];
}

interface Actions {
  load(): Promise<void>;
  addReservationWithMinimalData(
    data: Pick<Reservation, 'typeHandle' | 'pageDataRaw'>,
  ): Promise<void>;
  addReservationForShopOrder(
    data: Pick<Reservation, 'typeHandle' | 'shopOrder'>,
  ): Promise<void>;
  addReservationForShopReturn(
    data: Pick<Reservation, 'typeHandle' | 'shopReturn'>,
  ): Promise<void>;
  addReservationForShopComplaint(
    data: Pick<Reservation, 'typeHandle' | 'shopComplaint'>,
  ): Promise<void>;
  addReservation(data: {
    formSubmissionResult: Record<string, unknown>;
    formDataRaw: Record<string, unknown>;
    pageDataRaw: BasePageData;
    entryDate: Date;
    type?: EFormType;
    price: number;
    grossPrice: number;
    shippingCosts?: number;
    vat?: number;
    hasAdditionalServiceSelected?: boolean;
    pricePerPerson?: number;
    servicePrice?: number;
    expectedAttendeesCount?: number;
    tags?: string[];
    typeHandle: string;
    auxiliaryService?: AuxiliaryServiceData | null;
  }): Promise<void>;
}

interface Getters extends _GettersTree<State> {
  lastReservation: (state: State) => Reservation | null;
  lastReserverationAreaId: () => string | null;
}

export const useReservation = defineStore<
  StoreIds.RESERVATION,
  State,
  Getters,
  Actions
>(StoreIds.RESERVATION, {
  state: () => ({
    reservations: [] as Reservation[],
  }),

  actions: {
    async load() {
      return;
    },
    async addReservationWithMinimalData(data) {
      const reservation: Reservation = {
        timestamp: new Date(),
        typeHandle: data.typeHandle,
        pageDataRaw: data.pageDataRaw,
      };

      this.reservations.push(reservation);
    },
    async addReservationForShopOrder(data) {
      const reservation: Reservation = {
        timestamp: new Date(),
        typeHandle: data.typeHandle,
        shopOrder: data.shopOrder,
      };

      this.reservations.push(reservation);
    },
    async addReservationForShopReturn(data) {
      const reservation: Reservation = {
        timestamp: new Date(),
        typeHandle: data.typeHandle,
        shopReturn: data.shopReturn,
      };

      this.reservations.push(reservation);
    },
    async addReservationForShopComplaint(data) {
      const reservation: Reservation = {
        timestamp: new Date(),
        typeHandle: data.typeHandle,
        shopComplaint: data.shopComplaint,
      };

      this.reservations.push(reservation);
    },
    async addReservation(data) {
      let formData = null;
      /**
       * Has to use key because the key is different for every submission-type
       */
      for (const key in data.formSubmissionResult) {
        formData = data.formSubmissionResult[key];
        break;
      }
      const reservation: Reservation = {
        timestamp: new Date(),
        formSubmissionData: formData,
        formDataRaw: data.formDataRaw,
        pageDataRaw: data.pageDataRaw,
        entryDate: data.entryDate,
        type: data.type,
        price: {
          full: data.price,
          gross: data.grossPrice,
          shipping: data.shippingCosts,
          vat: data.vat,
          perPerson: data.pricePerPerson,
          service: data.servicePrice,
        },
        hasAdditionalServiceSelected: data.hasAdditionalServiceSelected
          ? data.hasAdditionalServiceSelected
          : false,
        expectedAttendeesCount: data.expectedAttendeesCount,
        tags: data.tags ? data.tags : [],
        typeHandle: data.typeHandle,
        auxiliaryService: data.auxiliaryService,
      };
      this.reservations.push(reservation);
      return;
    },
  },
  getters: {
    lastReservation: (state) => {
      if (state.reservations.length > 0) {
        return state.reservations[state.reservations.length - 1];
      } else {
        return null;
      }
    },
    lastReserverationAreaId() {
      if (this.lastReservation?.pageDataRaw?.area) {
        return this.lastReservation.pageDataRaw.area.id;
      }

      if (this.lastReservation?.shopOrder) {
        return useAreaStore().getActiveArea?.id;
      }
    },
  },
});

export type ReturnCreationData = {
  shopReturn: ReturnCreateResponse;
  typeHandle: 'shop_return_created';
};

export type ComplaintCreationData = {
  shopReturn: ComplaintCreateResponse;
  typeHandle: 'shop_complaint_created';
};
