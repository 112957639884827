import { useStorage } from '@vueuse/core';

export function infobannerInStorage() {
  const site = useNuxtApp().$siteIdent;
  switch (site) {
    case SiteIdent.default:
      useStorage('infobannerVdv', true);
      break;
    case SiteIdent.miniluDe:
    case SiteIdent.miniluAt:
    case SiteIdent.miniluNl:
      useStorage('infobannerMinilu', true);
  }
}
