<template>
  <ClientOnly>
    <Transition
      v-if="store.open && map[store.dialogIdent as DialogIdent]"
      name="fade"
    >
      <div id="dialogRoot">
        <component :is="map[store.dialogIdent as DialogIdent]" />
      </div>
    </Transition>
  </ClientOnly>
</template>
<script setup lang="ts">
import type { DialogIdent } from '~/stores/useDialogStore';
import { useDialogStore } from '~/stores/useDialogStore';
import { useDialogMap } from './dialogMap';

const store = useDialogStore();
const map = useDialogMap();
</script>
