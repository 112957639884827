export type Subdomain = 'vdv' | 'minilu';

export const layoutForRoutes: Record<Subdomain, Record<string, string>> = {
  minilu: {
    default: 'minilu',
    '/repairshop/checkout': 'repairshop-minilu',
    '/register': 'minilu-sub-view',
    '/login': 'minilu-sub-view',
    '/account/resetPassword': 'minilu-sub-view',
    '/account/changePassword': 'minilu-sub-view',
    '/checkout/login': 'minilu-checkout-sub-view',
    '/checkout/address': 'minilu-checkout-sub-view',
    '/checkout/payment-shipping': 'minilu-checkout-sub-view',
    '/checkout/review': 'minilu-checkout-sub-view',
    '/account/returns/checkout/items': 'minilu-return-checkout-sub-view',
    '/account/returns/checkout/shipping': 'minilu-return-checkout-sub-view',
    '/account/returns/checkout/review': 'minilu-return-checkout-sub-view',
    '/_/banner/overview': 'banner',
    '/productlists/viewed-products': 'minilu-with-fav-list-nav',
    '/productlists/ordered-products': 'minilu-with-fav-list-nav',
    '/productlists/order-alarm': 'minilu-with-fav-list-nav',
    '/productlists/auto-delivery': 'minilu-with-fav-list-nav',
    '/productlists/favorites': 'minilu-with-fav-list-nav',
    '/productlists/favorites/:slug(.*)*/:id()': 'minilu-with-fav-list-nav',
  },
  vdv: {
    default: 'vdv',
    '/repairshop/checkout': 'repairshop-vdv',
    '/register': 'vdv-sub-view',
    '/login': 'vdv-sub-view',
    '/account/resetPassword': 'vdv-sub-view',
    '/account/changePassword': 'vdv-sub-view',
    '/checkout/login': 'vdv-checkout-sub-view',
    '/checkout/address': 'vdv-checkout-sub-view',
    '/checkout/payment-shipping': 'vdv-checkout-sub-view',
    '/checkout/review': 'vdv-checkout-sub-view',
    '/account/returns/checkout/items': 'vdv-return-checkout-sub-view',
    '/account/returns/checkout/shipping': 'vdv-return-checkout-sub-view',
    '/account/returns/checkout/review': 'vdv-return-checkout-sub-view',
    '/_/banner/overview': 'banner',
    '/productlists/viewed-products': 'vdv-with-fav-list-nav',
    '/productlists/ordered-products': 'vdv-with-fav-list-nav',
    '/productlists/order-alarm': 'vdv-with-fav-list-nav',
    '/productlists/auto-delivery': 'vdv-with-fav-list-nav',
    '/productlists/favorites': 'vdv-with-fav-list-nav',
    '/productlists/favorites/:slug(.*)*/:id()': 'vdv-with-fav-list-nav',
  },
};

export function useInitialLayout(to?: string, subdom?: string): string {
  const subdomain = <Subdomain>subdom ?? (useNuxtApp().$subdomain as Subdomain);
  const route = to ?? useRoute().path;

  const layout = route.startsWith('/preview/banner')
    ? 'banner'
    : layoutForRoutes[subdomain]?.[route];
  return layout ?? layoutForRoutes[subdomain].default;
}
