import { shopPagesPatterns } from '~/plugins/changeRoutes';

export default defineNuxtRouteMiddleware((to) => {
  if (to.path === '/employee/recover/password') {
    const hash = to.query.hash as string;
    return navigateTo({ path: '/account/resetPassword', query: { hash } });
  }

  if (
    !useShopEnabled() &&
    shopPagesPatterns.some((regex) => regex.test(to.path))
  ) {
    return navigateTo({ path: '/404' });
  }
});
