import { useCheckoutStore, CheckoutSteps } from '@/stores/useCheckout';
import useDrugContactDialog from '@/composables/dialogs/useDrugContactAddressChoiceDialog';
import { useCartStore } from '~/stores/useCart';
import { useUserAddress } from '@/stores/useUserAddress';

export default async function useDrugContactAddressChoice() {
  const steps = [CheckoutSteps.ADDRESS, CheckoutSteps.REVIEW];
  for (const step of steps) {
    await useCheckoutStore().registerEvent(
      step,
      async () => {
        if (
          useUserAddress().shippingAddressIsContactAddress ||
          !useCartStore().hasDrugItem
        ) {
          return true;
        }

        if (step === CheckoutSteps.REVIEW) {
          useCheckoutStore().haltProgression();
        }

        return await useDrugContactDialog()
          .open()
          .then(async () => {
            await useCartStore().eventQueueRunningPromise();
            if (useCartStore().cartItemAmount < 1) {
              await navigateTo('/checkout/basket');
            }
            return true;
          })
          .catch(() => {
            return false;
          });
      },
      {
        id: 'DrugAddressChoiceHint_' + step,
        type: 'ON_NEXT',
        runEveryTime: true,
      },
    );
  }
}
