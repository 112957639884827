import StoreIds from './storeIds';
import {
  useSecureSessionPost,
  useSessionGet,
} from '@/composables/dataFetching/genericFetchers';
import type { Address } from '@/server/transformers/shop/addressTransformer';
import type { Result as AddressResult } from '@/server/api/[site]/user/data/addresses.get';
import type { CountryData } from '@/server/gateway/connections/shopware/loaders/loadCountries';
import { useUserContext } from '@/stores/useUserContext';
import type { Body as ChangeContextBody } from '@/server/api/[site]/user/context.post';
import type {
  Result as CreateAddressResult,
  PostBody as CreateAddressBody,
  Result as EditAddressResult,
  PostBody as EditAddressBody,
} from '@/server/api/[site]/user/account/newAddress.post';
import { handleLoadingError } from '~/utils/handleLoadingError';

interface State {
  addresses?: Address[];
  contactAddress?: Address;
  countries?: CountryData[];
  initialized: boolean;
  isLoading: boolean;
}

/** @Info When in doubt use INDIFFERENT as a Wildcard */
export enum AddressType {
  SHIPPING = 'shipping',
  BILLING = 'billing',
  INDIFFERENT = 'indifferent',
}

export const useUserAddress = defineStore(StoreIds.USER_ADDRESS, {
  state: () =>
    ({
      addresses: [],
      contactAddress: null,
      countries: [],
      initialized: false,
      isLoading: false,
    }) as State,
  actions: {
    async loadAddresses(refresh = false) {
      if (!refresh && this.addresses.length) return this.addresses;
      try {
        const site = useSiteIdent();
        const result = await useSessionGet<AddressResult>(
          `/api/${site}/user/data/addresses`,
        );
        this.addresses = result?.addresses || [];
        this.contactAddress = result?.contactAddress || null;
        this.countries = result?.countries || null;
        this.initialized = true;
        return this.addresses;
      } catch (e) {
        return this.addresses;
      }
    },
    async setDefaultBillingAddress(addressId: string) {
      this.isLoading = true;
      try {
        const result = await useSecureSessionPost<boolean>(
          `/api/${useSiteIdent()}/user/account/setDefaultBillingAddress`,
          { addressId },
        );
        await this.loadAddresses(true);
        this.isLoading = false;
        return result;
      } catch (e) {
        this.isLoading = false;
        handleLoadingError(e);
        return false;
      }
    },
    async setDefaultShippingAddress(addressId: string) {
      this.isLoading = true;
      try {
        const result = await useSecureSessionPost<boolean>(
          `/api/${useSiteIdent()}/user/account/setDefaultShippingAddress`,
          { addressId },
        );
        await this.loadAddresses(true);
        this.isLoading = false;
        return result;
      } catch (e) {
        this.isLoading = false;
        handleLoadingError(e);
        return false;
      }
    },
    async setActiveAddress(
      body: Partial<
        Pick<ChangeContextBody, 'billingAddressId' | 'shippingAddressId'>
      >,
    ) {
      this.isLoading = true;
      await useUserContext().changeUserContext(body);
      this.isLoading = false;
    },
    async setActiveBillingAddressToContactAddress() {
      this.isLoading = true;
      if (!this.initialized || !this.contactAddress) {
        await this.loadAddresses();
      }
      await this.setActiveAddress({
        billingAddressId: this.contactAddress.id,
      });
    },
    /**
     * @param options.autoChangeActiveAddress
     * If true, then when changing the address AND when it's set as default-address,
     * we will also try to change the active-address to this new default-address
     * Business Logic may prevent this for users without an order for the Billing-Address
     * @returns New Address or false (if failed)
     */
    async createAddress(
      addressType: AddressType,
      addressData: CreateAddressBody,
      options?: { autoChangeActiveAddress?: boolean },
    ) {
      this.isLoading = true;
      try {
        const result = await useSecureSessionPost<CreateAddressResult>(
          `/api/${useSiteIdent()}/user/account/newAddress`,
          addressData,
        );

        await useUserContext().loadUserContext(true);
        if (
          options?.autoChangeActiveAddress &&
          result &&
          typeof result !== 'boolean' &&
          result?.id
        ) {
          const newActiveAddresses =
            findNewActiveAddressesFromCreateEditAddress(
              addressType,
              result?.id,
              {
                defaultBillingAddress: addressData.isDefaultBillingAddress,
                defaultShippingAddress: addressData.isDefaultShippingAddress,
              },
            );
          const args = getActiveAddressChangeArgs({
            ...newActiveAddresses,
            canEdit: this.canEdit,
            contactAddressId: this.contactAddress?.id,
            activeBillingAddressId: useUserContext().activeBillingAddressId,
            activeShippingAddressId: useUserContext().activeShippingAddressId,
          });

          if (args) {
            await this.setActiveAddress(args);
          }
        }

        await this.loadAddresses(true);
        this.isLoading = false;
        return result;
      } catch (e) {
        this.isLoading = false;
        handleLoadingError(e);
        return false;
      }
    },
    /**
     * @param options.autoChangeActiveAddress
     * If true, then when changing the address AND when it's set as default-address,
     * we will also try to change the active-address to this new default-address
     * Business Logic may prevent this for users without an order for the Billing-Address
     * @returns Edited Address or false (if failed)
     */
    async editAddress(
      addressType: AddressType,
      addressData: EditAddressBody,
      addressId: string,
      options?: { autoChangeActiveAddress?: boolean },
    ) {
      this.isLoading = true;
      try {
        const result = await useSecureSessionPost<EditAddressResult>(
          `/api/${useSiteIdent()}/user/account/editAddress`,
          {
            id: addressId,
            ...addressData,
          } as EditAddressBody,
        );

        await useUserContext().loadUserContext(true);
        if (options?.autoChangeActiveAddress) {
          const newActiveAddresses =
            findNewActiveAddressesFromCreateEditAddress(
              addressType,
              addressId,
              {
                defaultBillingAddress: addressData.isDefaultBillingAddress,
                defaultShippingAddress: addressData.isDefaultShippingAddress,
              },
            );
          const args = getActiveAddressChangeArgs({
            ...newActiveAddresses,
            canEdit: this.canEdit,
            contactAddressId: this.contactAddress?.id,
            activeBillingAddressId: useUserContext().activeBillingAddressId,
            activeShippingAddressId: useUserContext().activeShippingAddressId,
          });

          if (args) {
            await this.setActiveAddress(args);
          }
        }

        await this.loadAddresses(true);
        this.isLoading = false;
        return result;
      } catch (e) {
        this.isLoading = false;
        handleLoadingError(e);
        return false;
      }
    },
    async deleteAddress(addressId: string) {
      this.isLoading = true;
      try {
        const result = await useSecureSessionPost<boolean>(
          `/api/${useSiteIdent()}/user/account/deleteAddress`,
          { addressId },
        );

        await this.loadAddresses(true);
        this.isLoading = false;
        return result;
      } catch (e) {
        this.isLoading = false;
        handleLoadingError(e);
        return false;
      }
    },
    reset() {
      this.isLoading = true;

      this.addresses = [];
      this.contactAddress = null;
      this.countries = [];
      this.initialized = false;

      this.isLoading = false;
    },
  },
  getters: {
    activeBillingAddress(): Address {
      return this.allAddresses.find(
        (address) => useUserContext().activeBillingAddressId === address.id,
      );
    },
    activeShippingAddress(): Address {
      return this.allAddresses.find(
        (address) => useUserContext().activeShippingAddressId === address.id,
      );
    },
    allAddresses(): Address[] {
      if (!this.initialized) return [];
      else return [this.contactAddress, ...this.addresses];
    },
    canEdit(): boolean {
      return useUserContext().accountData?.canEdit;
    },
    shippingAddressIsContactAddress(): boolean {
      return (
        useUserContext().activeShippingAddressId === this.contactAddress?.id
      );
    },
    billingAddressIsContactAddress(): boolean {
      return (
        useUserContext().activeBillingAddressId === this.contactAddress?.id
      );
    },
  },
});

/**@returns New potential active Billig- and/or Shipping-Address-Id, or null if none applies */
function findNewActiveAddressesFromCreateEditAddress(
  addressType: AddressType,
  addressId: string,
  isNew: { defaultBillingAddress: boolean; defaultShippingAddress: boolean },
): Pick<
  Parameters<typeof getActiveAddressChangeArgs>[0],
  'newActiveShippingAddressId' | 'newActiveBillingAddressId'
> {
  switch (true) {
    case addressType === AddressType.SHIPPING && isNew.defaultBillingAddress:
    case addressType === AddressType.BILLING && isNew.defaultShippingAddress:
      return {
        newActiveBillingAddressId: addressId,
        newActiveShippingAddressId: addressId,
      };
    case addressType === AddressType.SHIPPING:
      return {
        newActiveShippingAddressId: addressId,
      };
    case addressType === AddressType.BILLING:
      return {
        newActiveBillingAddressId: addressId,
      };
    case addressType === AddressType.INDIFFERENT &&
      isNew.defaultShippingAddress &&
      isNew.defaultBillingAddress:
      return {
        newActiveBillingAddressId: addressId,
        newActiveShippingAddressId: addressId,
      };
    case addressType === AddressType.INDIFFERENT &&
      isNew.defaultShippingAddress:
      return {
        newActiveShippingAddressId: addressId,
      };
    case addressType === AddressType.INDIFFERENT && isNew.defaultBillingAddress:
      return {
        newActiveBillingAddressId: addressId,
      };
    default:
      return null;
  }
}

/** @summary
 * this handles business logic
 * e.g. if an new active Address should REALLY bet set,
 * or if we should just not set it or set the contact address instead
 * @returns ChangeContextBody Arguments for API call or null
 */
function getActiveAddressChangeArgs(options?: {
  canEdit?: boolean;
  newActiveShippingAddressId?: string;
  activeShippingAddressId?: string;
  newActiveBillingAddressId?: string;
  activeBillingAddressId?: string;
  contactAddressId?: string;
}) {
  if (!options) return;
  const args: Partial<ChangeContextBody> = {};
  // Handle Billing Address
  if (options.newActiveBillingAddressId && !options.canEdit) {
    args.billingAddressId = options.newActiveBillingAddressId;
    //Set active address to contact address if user hasn' t ordered yet (canEdit)
  } else if (options.contactAddressId && options.canEdit) {
    args.billingAddressId = options.contactAddressId;
  }

  // Handle Shipping Address
  if (options.newActiveShippingAddressId) {
    args.shippingAddressId = options.newActiveShippingAddressId;
  }
  return Object.keys(args).length ? args : null;
}
