import { type _GettersTree, defineStore } from 'pinia';
import storeIds from './storeIds';
import {
  type AreaData,
  generateSectionColor,
  getAreaColors,
} from '../server/transformers/EntityTransformers/areaTransformer';
import type { AreasForNotFoundPageData } from '../server/transformers/404areasTransformer';
import { useCachedGet } from '../composables/dataFetching/genericFetchers';
import { handleLoadingError } from '~/utils/handleLoadingError';

const CssVar_ConcreteAppendices = [
  'base',
  'alpha',
  'beta',
  'light',
  'font-base',
  'font-alpha',
  'deco-base',
  'deco-alpha',
  'border-base',
  'border-alpha',
];

interface State {
  areas: AreaData[];
  activeAreaId: number;
  globalStyles: string;
  defaultArea: AreaData | any;
}

interface Getters extends _GettersTree<State> {
  get404Areas: (state: State) => Promise<AreaData[]>;
  getActiveArea: (state: State) => AreaData;
  activeAreaStyles: (state: State) => string;
  areaStyles: (state: State) => string;
}

interface Actions {
  init(areas: AreaData[], defaultArea: AreaData): void;
  setAreas(data: AreaData[]): void;
  findAreaById(areaId: string): AreaData;
  setActiveAreaById(areaId: string): void;
  setDefaultArea(): void;
}

export const useAreaStore = defineStore<
  storeIds.AREA_CONFIG,
  State,
  Getters,
  Actions
>(storeIds.AREA_CONFIG, {
  state: () => ({
    areas: [],
    activeAreaId: null,
    globalStyles: null,
    defaultArea: null,
  }),

  getters: {
    get404Areas: async (state): Promise<AreaData[]> => {
      try {
        const { data: areas404 } = await useCachedGet<
          AreasForNotFoundPageData[] | string[]
        >(`/api/${useSiteIdent()}/content/404areaIds`);

        if (!areas404.value?.length) return [];

        const mapped404Areas = areas404.value.map((area) =>
          state.areas.find((entry) => area === entry.id),
        );

        return mapped404Areas;
      } catch (e) {
        handleLoadingError(e);
        return [];
      }
    },
    getActiveArea: (state): AreaData => {
      return state.activeAreaId
        ? state.areas.find((x) => +x.id === state.activeAreaId)
        : null;
    },
    activeAreaStyles: (state): string => {
      const colors: Array<string> = [];
      if (!state.activeAreaId) {
        return '';
      }
      for (const appendix of CssVar_ConcreteAppendices) {
        const identifier = state.areas.find(
          (x) => +x.id === state.activeAreaId,
        )?.id;
        colors.push(
          `--activesection-${appendix}:var(--${identifier}-${appendix})`,
        );
      }
      return colors.join('; ');
    },
    areaStyles: (state): string => {
      const colors: Array<string> = [];
      const site = useSiteIdent();
      for (const area of state.areas) {
        const sectionColor = generateSectionColor(area);
        colors.push(
          getAreaColors(
            sectionColor,
            site,
            area.id === state.defaultArea.id,
          ).join('; '),
        );
      }
      return colors.join('; ');
    },
  },

  actions: {
    init(areas: AreaData[], defaultArea: AreaData) {
      this.setAreas(areas);
      this.defaultArea = defaultArea;
    },
    setAreas(inputAreas: AreaData[]) {
      this.areas = inputAreas;
    },
    findAreaById(areaId) {
      const area = this.areas.find((entry) => entry.id === areaId);
      return area;
    },
    setActiveAreaById(areaId?: string) {
      this.activeAreaId = areaId ? +areaId : null;
    },
    setDefaultArea() {
      if (this?.defaultArea?.id) this.activeAreaId = +this?.defaultArea?.id;
    },
  },
});
