import { useCheckoutStore, CheckoutSteps } from '@/stores/useCheckout';
import { useUserContext } from '@/stores/useUserContext';
import useAvailableShippingMethods from '~/composables/checkout/useAvailableShippingMethods';

export default async function useUpdateShippingMethodEvent() {
  const userContext = useUserContext();

  [CheckoutSteps.PAYMENT_SHIPPING, CheckoutSteps.REVIEW].forEach(
    async (step) => {
      await useCheckoutStore().registerEvent(
        step,
        async () => {
          const shippingMethods = await useAvailableShippingMethods(true);

          if (
            !shippingMethods?.value.find(
              (x) =>
                x.id === userContext.userContext?.selectedShippingMethod?.id,
            )?.isAvailable
          ) {
            await userContext.changeUserContext({
              shippingMethodId: shippingMethods?.value.find(
                (x) => x.isAvailable,
              )?.id,
            });
          }
          return true;
        },
        {
          id: step + '_updateShippingMethodIfCurrentIsNotAvailable',
          type: 'ON_ENTER',
          runEveryTime: true,
        },
      );
    },
  );
}
