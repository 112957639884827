<template>
  <!-- BG -->
  <div
    style="
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding-left: 10vw;
      background: linear-gradient(#0000001a, #00000008);
    "
  >
    <div
      style="
        border-bottom: 100vh solid #00000008;
        border-left: 40vh solid transparent;
        height: 100%;
        width: 100%;
      "
    ></div>
  </div>

  <div style="position: absolute; left: 0; top: 0; width: 100%; height: 100%">
    <!-- GFX -->
    <div
      style="
        margin: auto;
        max-width: 450px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding: 30px 15px 15px 15px;
        font-family:
          system-ui,
          -apple-system,
          BlinkMacSystemFont,
          Roboto,
          Oxygen,
          Ubuntu,
          Cantarell,
          'Open Sans',
          'Helvetica Neue',
          sans-serif;
        font-size: 16px;
        color: 000000;
      "
    >
      <div style="display: inline-block; flex-grow: 1; align-self: flex-end">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          viewBox="0 0 169 167.555"
        >
          <g transform="translate(-2480.044 -1135.099)">
            <path
              d="M2621.422,1144.855c-25.025-17.309-60.485-4.387-64.458-2.854-3.29-1.08-6.32-2.206-8.731-3.128-6.517-2.491-19.55-5.482-31.08,1.994s-10.026,15.949-10.026,15.949l-2.506,1.495s-5.659,3.929-8.021,8.971c-1.145,2.445.01,5.666.5,6.48,1.5,2.493,4.01,5.98,4.01,5.98s-20.052,17.445-20.052,19.937,10.527,7.476,17.044,10.965,18.548,8.473,18.548,8.473,12.031,19.937,12.532,44.359c.256,12.458-3.008,26.914-3.008,26.914s-5.013,2.493-5.013,5.981,2.506,4.984,9.023,4.984,9.524-1.5,9.023-4.984-5.515-5.483-5.515-5.483a341.452,341.452,0,0,1,30.079-27.911c2.5-2.021,5.028-3.872,7.509-5.557l.01.075s3.007,1,5.013,7.975,3.008,19.1,2.005,21.089-2.005,3.987-2.005,5.982,1,4.984,6.016,4.984h10.026c2.006,0,6.016,0,6.016-1.994,0-7.974-11.029-4.984-11.029-7.974,0-3.987,13.633-11.317,27.07-38.035C2625.432,1227.591,2644.481,1160.8,2621.422,1144.855Zm-120.407,60.717-5.706-3.6-3.318-3.418,9.973-13.477,3.583,11.007,2.944,6.271,4.554,8.229Z"
              transform="translate(0.003 0.005)"
              fill="#fff"
            />
            <path
              d="M2596.765,1244.174a125.649,125.649,0,0,0-33.918,20.271c-5.063,4.255-9.97,8.7-14.766,13.25s-9.477,9.243-13.985,14.06l-.243-1.086c2.406,1.085,4.906,2.653,5.811,5.314,1.042,3.228-1.281,5.608-4.391,6.139-4.085.687-12.947,1.511-14.726-3.267-1.464-3.912,2.008-7.2,5.149-8.834l-.456.583c2.426-11.944,3.94-24.326,2.137-36.459a101.423,101.423,0,0,0-11.675-34.522l.472.408a248.5,248.5,0,0,1-23.6-11.229c-3.87-2.139-7.9-3.979-11.286-6.973a3.136,3.136,0,0,1-1.221-2.55,4.258,4.258,0,0,1,.715-1.438c5.823-7.084,12.915-12.856,19.66-18.991l-.135,1.162c-2.717-3.976-7.041-8.907-4.291-13.929a27,27,0,0,1,7.843-8.6,4,4,0,0,1,.417-.277l.788-.469,1.578-.937-.357.781c-.618-8.466,6.644-15.044,13.6-18.494,7.2-3.5,15.624-3.711,23.271-1.743,7.5,2.182,14.584,5.652,22.231,7.39,7.518,1.991,16.006,2.917,21.848-3.326-5.8,6.473-14.286,5.7-21.969,3.831-7.669-1.592-14.818-4.916-22.34-6.962-7.446-1.785-15.582-1.539-22.492,1.931-6.519,3.323-13.543,9.684-12.589,17.634l-.428.259c-.534.34-1.8,1.077-2.311,1.4a26.661,26.661,0,0,0-3.353,2.828c-1.99,2.025-4.033,4.284-4.793,7-.705,4.229,2.676,7.377,4.76,10.677l.455.641-.589.521c-6.586,6.024-13.631,11.846-19.293,18.736a3.451,3.451,0,0,0-.4.7c-.007-.114-.012.036.127.243,3.04,3.28,7.556,5.052,11.442,7.273a249.222,249.222,0,0,0,23.794,11.206c9.084,16.439,14.067,35.438,12.426,54.239a147.874,147.874,0,0,1-2.839,18.808c-2.522,1.331-5.829,3.7-4.7,6.921,1.129,2.76,5.332,2.641,8.007,2.747,2.626-.085,7.021.069,8.16-2.607.888-3.531-3.091-5.743-5.855-6.906l.659-.687c18.436-18.857,38.372-37.665,63.655-46.661Z"
              transform="translate(0 0.003)"
              fill="#cbcbcb"
            />
            <path
              d="M2513.02,1211.537a.991.991,0,0,1-.317-.052c-.373-.124-9.185-3.074-12.271-5.129-8.153-5.436-9.469-6.766-9.469-7.852,0-1.745,6.246-8.667,6.31-8.73,1.237-1.239,6.724-8.9,6.724-11.322,0-.954-.11-2.061-.217-3.131-.245-2.44-.474-4.745.324-6.342a25.371,25.371,0,0,1,2.791-3.813,21.138,21.138,0,0,0,2.116-2.834,5.87,5.87,0,0,0-.193-1.939c-1.4-.173-2.431-2.172-2.824-3.054a1,1,0,0,1,1.832-.816,5.513,5.513,0,0,0,1.258,1.878c1.933.065,1.933,2.723,1.933,4.009,0,.813-.7,1.751-2.569,4.026a25.3,25.3,0,0,0-2.55,3.44c-.538,1.077-.327,3.2-.122,5.245.112,1.119.228,2.278.228,3.331,0,1.331-.922,3.349-2.114,5.392a.988.988,0,0,1,.012.157,38.741,38.741,0,0,0,4.006,15.068c2.677,5.531,5.936,10.889,5.97,10.943a1,1,0,0,1-.856,1.525Zm-19.983-13.11c1.011,1.262,6.607,4.993,8.508,6.26,1.885,1.255,6.36,2.967,9.223,4-1.221-2.12-3.048-5.4-4.666-8.744a43.12,43.12,0,0,1-4-13.275,47.114,47.114,0,0,1-3.41,4.524A56.143,56.143,0,0,0,2493.037,1198.427Zm-.116-.171"
              transform="translate(0.028 0.057)"
              fill="#cbcbcb"
            />
            <path
              d="M2621.965,1203.529s19.05-12.031,19.05-13.034-.822-1.508-6.016-11.028c-6.016-11.029-14.036-12.031-14.036-13.034s2.005-2.005,4.011-2.005,6.624,3.68,6.624,3.68-3.616-7.69-3.616-10.7,1-7.018,2.005-7.018,2.005,8.021,5.013,15.039,13.034,23.06,13.034,26.067-4.01,5.013-9.023,9.023-16.042,12.031-16.042,12.031Z"
              transform="translate(0.365 0.043)"
              fill="#fff"
            />
            <path
              d="M2622.97,1212.555c6.338-5.076,12.714-10,19.1-15.042a26.444,26.444,0,0,0,4.459-3.873,3.115,3.115,0,0,0,.8-2.334c-1.449-4.643-4.443-8.91-6.722-13.269-1.963-3.545-3.962-7.069-5.764-10.757-2.276-4.661-3.433-9.642-4.792-14.544a8.539,8.539,0,0,0-.561-1.53c-.016-.066-.082-.024.187.111a.667.667,0,0,0,.579,0,7.9,7.9,0,0,0-.87,2.489,12.906,12.906,0,0,0,.785,8.363c1.021,2.9,2.587,6.229,3.869,9.049-2.471-1.884-5.287-4.4-8.136-5.637a4.119,4.119,0,0,0-3.342.3,1.655,1.655,0,0,0-.71.568c-.013.012,0,0,0-.012a.69.69,0,0,0-.073-.307c-.008-.042-.081-.111-.072-.106a4.694,4.694,0,0,0,.662.371c10.354,4.494,12.971,12.8,18.226,21.76a5.545,5.545,0,0,1,.95,2.31c-.014.437-.476.837-.79,1.085-5.982,4.417-12.442,8.131-18.787,11.98,6.038-4.207,13.352-8.771,18.513-13.136-1.406-2.995-3.606-5.981-5.172-8.971-2.557-4.884-6.085-9.328-10.936-12.042-.862-.5-1.869-.98-2.779-1.412a4.467,4.467,0,0,1-1.148-.71,1.255,1.255,0,0,1-.361-.875c.292-2.09,2.826-2.7,4.571-2.848a4.1,4.1,0,0,1,.672.019c2.652.545,4.726,2.294,6.857,3.82l-1.435,1.134c-.87-1.872-1.674-3.74-2.389-5.679a15.738,15.738,0,0,1-1.344-6.278,13.507,13.507,0,0,1,1.648-6.388,2.217,2.217,0,0,1,.7-.607,1.328,1.328,0,0,1,1.587.323,6.792,6.792,0,0,1,.97,2.311c1.3,4.852,2.41,9.822,4.559,14.31,1.725,3.608,3.693,7.154,5.623,10.711,1.915,3.575,3.856,7.129,5.537,10.883a12.441,12.441,0,0,1,1.014,3.087,4.4,4.4,0,0,1-1.149,3.233,27.1,27.1,0,0,1-4.788,3.968c-6.512,4.772-13.141,9.51-19.749,14.169Z"
              transform="translate(0.363 0.04)"
              fill="#cbcbcb"
            />
            <path
              d="M2545.255,1183.155a6.453,6.453,0,0,1-.668-8.143c2.84-3.794,7.918-4.763,12.323-4.607l.02.3c-3.055.757-6.4,1.935-8.6,4.122a13.974,13.974,0,0,0-2.8,8.194l-.269.134Z"
              transform="translate(0.165 0.095)"
              fill="#cbcbcb"
            />
            <path
              d="M2590.874,1171.571c6.063-3.6,13.772,3.625,11.17,10l-.26.018a28.675,28.675,0,0,1-2.008-3.878c-.569-1.205-.788-2.557-1.574-3.577a6.735,6.735,0,0,0-7.214-2.331l-.114-.235Z"
              transform="translate(0.287 0.095)"
              fill="#cbcbcb"
            />
            <ellipse
              cx="5.022"
              cy="8.522"
              rx="5.022"
              ry="8.522"
              transform="translate(2553.056 1186.691)"
              fill="#707070"
            />
            <ellipse
              cx="4.4"
              cy="8.522"
              rx="4.4"
              ry="8.522"
              transform="translate(2591.492 1184.42)"
              fill="#707070"
            />
            <ellipse
              cx="11.023"
              cy="7.757"
              rx="11.023"
              ry="7.757"
              transform="translate(2569.956 1233.446) rotate(-85.392)"
              fill="#cbcbcb"
            />
          </g>
        </svg>
      </div>

      <div
        style="
          font-size: 120px;
          line-height: 120px;
          padding-right: 30px;
          display: inline-block;
          font-weight: bolder;
          color: #707070;
        "
      >
        {{ error.statusCode }}
        <div style="position: relativ">
          <div
            style="position: absolute; margin-top: -60px; margin-left: 178px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              viewBox="0 0 61.129 54.768"
            >
              <g transform="translate(-5474 669.906)">
                <path
                  d="M51.076,39.407a6.618,6.618,0,0,1,11.468,0L86.481,80.939a6.618,6.618,0,0,1-5.734,9.923H32.874a6.618,6.618,0,0,1-5.734-9.923Z"
                  transform="translate(5447.754 -706)"
                  fill="#ef4444"
                />
                <path
                  d="M15.752-147.509a2.341,2.341,0,0,0,2.446,2.584c1.523,0,2.353-.969,2.4-2.584l.554-16.566a2.825,2.825,0,0,0-2.953-3.092,2.8,2.8,0,0,0-2.953,3.092Zm2.446,5.122a3.056,3.056,0,0,0-3.138,3.138,3.046,3.046,0,0,0,3.138,3.092,3.035,3.035,0,0,0,3.092-3.092A3.046,3.046,0,0,0,18.2-142.386Z"
                  transform="translate(5486.517 -486.977)"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <!-- Content -->
    <div style="padding: 30px 15px 0 15px">
      <h1
        style="
          font-weight: bold;
          font-size: 28px;
          text-align: center;
          text-transform: none;
          letter-spacing: 0;
        "
      >
        {{ translations.title }}
      </h1>
      <p style="text-align: center">
        {{ translations.text }}
      </p>
    </div>

    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 30px;
      "
    >
      <div
        v-if="!isProd"
        style="
          position: relative;
          padding: 15px;
          margin: 0 15px 5px 15px;
          background-color: #ffffff66;
          border-radius: 12px;
        "
      >
        <table>
          <tr>
            <td
              style="
                font-weight: bold;
                color: #555555;
                vertical-align: top;
                padding-right: 10px;
              "
            >
              StatusCode:
            </td>
            <td>{{ error.statusCode }}</td>
          </tr>
          <tr>
            <td
              style="
                font-weight: bold;
                color: #555555;
                vertical-align: top;
                padding-right: 10px;
              "
            >
              Message:
            </td>
            <td>{{ error.message }}</td>
          </tr>
          <tr>
            <td
              colspan="2"
              style="max-width: calc(100vw - 60px); padding-top: 30px"
            >
              <div style="max-width: 708px" v-html="error.stack"></div>
            </td>
          </tr>
        </table>
      </div>
      <span
        v-if="!isProd"
        style="color: #ef4444; font-size: 14px; padding-bottom: 30px"
        >(Not shown on Prod)</span
      >

      <button
        style="
          align: center;
          padding: 6px 60px;
          margin-bottom: 30px;
          color: white;
          background-color: #555555;
          font-weight: bold;
          border-radius: 3px;
          display: inline-block;
        "
        @click="handleError"
      >
        zur Startseite
      </button>
    </div>
  </div>
  <DebugComponent type="sentry_replay" />
</template>
<script setup lang="ts">
import type { NuxtError } from '#app';
import DebugComponent from '@/components/debug/debugComponent.vue';

defineProps({
  error: {
    type: Object as () => NuxtError,
    required: true,
  },
});
const isProd = useRuntimeConfig().public.ENVIRONMENT_NAME === 'Prod';

const siteIdent = useSiteIdent();

const translations = computed(() => {
  switch (siteIdent) {
    case SiteIdent.miniluDe:
      return {
        title: 'Fehler beim Laden der Seite ',
        text: 'Huch, da ist etwas schiefgegangen (Internal Server Error). Bitte lade die Seite neu. Falls der Fehler bestehen bleibt, kontaktiere bitte meinen Kundenservice unter hallo@minilu.de.',
      };
    case SiteIdent.miniluAt:
      return {
        title: 'Fehler beim Laden der Seite ',
        text: 'Huch, da ist etwas schiefgegangen (Internal Server Error). Bitte lade die Seite neu. Falls der Fehler bestehen bleibt, kontaktiere bitte meinen Kundenservice unter hallo@minilu.at.',
      };
    case SiteIdent.miniluNl:
      return {
        title: 'Pagina kan niet worden geladen ',
        text: 'Oeps, er is iets misgegaan (internal server error). Laad de pagina opnieuw. Als de foutmelding niet verdwijnt, neem dan contact op met mijn klantenservice via hallo@minilu.nl.',
      };
    case SiteIdent.default:
    default:
      return {
        title: 'Fehler beim Laden der Seite ',
        text: 'Entschuldigung, etwas ist schiefgelaufen (Internal Server Error). Bitte laden Sie die Seite neu. Falls der Fehler bestehen bleibt, kontaktieren Sie bitte unseren Kundenservice unter info@vanderven.de.',
      };
  }
});

const handleError = () => clearError({ redirect: '/' });
</script>
<style>
pre {
  white-space: collapse;
  font-size: 11px;
}

pre > span {
  display: block;
  padding-bottom: 5px;
  word-wrap: break-word;
}
</style>
