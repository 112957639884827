export enum UseStateKeys {
  FA_ICONS = 'FA_ICONS',
  SUBNAV = 'SUBNAV',
  IS_PREVIEW = 'IS_PREVIEW',
  HOTLINK_BASKET_NOTIFICATION = 'HOTLINK_BASKET_NOTIFICATION',
  HOTLINK_MYPRODUCTS_NOTIFICATION = 'HOTLINK_MYPRODUCTS_NOTIFICATION',
  HOTLINK_NO_NOTIFICATION = 'HOTLINK_NO_NOTIFICATION',
  SHIPPING_METHODS = 'SHIPPING_METHODS',
  PAYMENT_METHODS = 'PAYMENT_METHODS',
  CMS_PAGE_DATA = 'CMS_PAGE_DATA',
  INFO_POPUP_OPENID = 'INFO_POPUP_OPENID',
  BANKDATA_INPUT_CHECKOUT = 'BANKDATA_INPUT_CHECKOUT',
  SENTRY_USER_ID = 'SENTRY_USER_ID',
  PRODUCT_REQUEST_CONTACT_DATA = 'PRODUCT_REQUEST_CONTACT_DATA',
}
