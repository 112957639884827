import { defineStore } from 'pinia';
import StoreIds from './storeIds';

export type RedirectData<T> = {
  source?: string;
  target?: string;
  data: T;
};
export const useRedirectData = defineStore(StoreIds.REDIRECT_DATA, {
  state: () => ({
    redirectData: new Map<string, RedirectData<any>>(),
  }),

  actions: {
    setData<T>(data: { key: string; value: RedirectData<T> }) {
      this.redirectData.set(data.key, data.value);
      return this.redirectData.get(data.key) as RedirectData<T>;
    },
    getData<T>(key: string) {
      return this.redirectData.get(key) as RedirectData<T>;
    },
    removeData(key: string) {
      this.redirectData.delete(key);
    },
    getAndRemoveData<T>(key: string) {
      const data = this.redirectData.get(key) as RedirectData<T>;
      this.redirectData.delete(key);
      return data;
    },
  },
});
