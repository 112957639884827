import validate from "/home/vsts/work/1/s/frontend/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/vsts/work/1/s/frontend/app/src/middleware/auth.global.ts";
import checkout_45global from "/home/vsts/work/1/s/frontend/app/src/middleware/checkout.global.ts";
import redirect_45global from "/home/vsts/work/1/s/frontend/app/src/middleware/redirect.global.ts";
import manifest_45route_45rule from "/home/vsts/work/1/s/frontend/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  checkout_45global,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}