import getSiteByHost from '@/utils/getSiteByHost';
import { SiteIdent } from '@/utils/types';

export default defineNuxtPlugin(async (nuxtApp) => {
  const hostname = nuxtApp.ssrContext
    ? nuxtApp.ssrContext.event.node.req.headers.host
    : location.host;

  const {
    siteIdent,
  }: {
    siteIdent: SiteIdent;
  } = getSiteByHost(hostname);

  const themeFilePath =
    siteIdent === SiteIdent.default ? '/themes/vdv.css' : '/themes/minilu.css';
  return {
    provide: {
      themeFilePath,
    },
  };
});
