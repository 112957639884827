import { DialogIdent } from '~/stores/useDialogStore';

const map: Record<
  string,
  Partial<Record<DialogIdent, ReturnType<typeof defineAsyncComponent>>>
> = {
  ['minilu']: {
    [DialogIdent.LOGIN]: defineAsyncComponent(
      () => import('~~/src/components/dialog/login/minilu/loginDialog.vue'),
    ),
    [DialogIdent.AUTO_LOGOUT]: defineAsyncComponent(
      () => import('~~/src/components/dialog/autologout/autoLogoutDialog.vue'),
    ),
    [DialogIdent.SEND_FORGOT_PASSWORD_EMAIL]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/sendForgotPassword/minilu/sendForgotPassword.vue'
        ),
    ),
    [DialogIdent.RESET_LEGACY_PASSWORD]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/resetLegacyPasswort/minilu/resetLegacyPasswort.vue'
        ),
    ),
    [DialogIdent.CHANGE_PASSWORD]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/changePassword/minilu/changePassword.vue'
        ),
    ),
    [DialogIdent.REGISTRATION]: defineAsyncComponent(
      () =>
        import('~~/src/components/dialog/registration/minilu/registration.vue'),
    ),
    [DialogIdent.EDIT_PROFILE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/editProfile/minilu/editProfileDialog.vue'
        ),
    ),
    [DialogIdent.NEW_EDIT_ADDRESS]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/newEditAddress/minilu/newEditAddress.vue'
        ),
    ),
    [DialogIdent.DELETE_ADDRESS]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/deleteAddress/minilu/deleteAddress.vue'
        ),
    ),
    [DialogIdent.UPLOAD_VERIFICATION_FILES]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/uploadVerificationFile/uploadVerificationFileDialog.vue'
        ),
    ),
    [DialogIdent.DELETE_VERIFICATION_FILE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/deleteVerificationFile/minilu/deleteVerificationFile.vue'
        ),
    ),
    [DialogIdent.ORDER_ALARM_STATUS]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/orderAlarm/minilu/editOrderAlarmStatus.vue'
        ),
    ),
    [DialogIdent.NEW_EDIT_FAVORITE_LIST]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/newEditFavoriteList/minilu/newEditFavoriteList.vue'
        ),
    ),
    [DialogIdent.DELETE_FAVORITE_LIST]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/deleteFavList/minilu/deleteFavList.vue'
        ),
    ),
    [DialogIdent.SUBSCRIPTION_DELIVERY_NOW]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/productSubscriptionDeliveryNow/productSubscriptionDeliveryNow.vue'
        ),
    ),
    [DialogIdent.SUBSCRIPTION_PAUSE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/productSubscriptionPause/productSubscriptionPause.vue'
        ),
    ),
    [DialogIdent.SUBSCRIPTION_EDIT]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/productSubscriptionEdit/productSubscriptionEdit.vue'
        ),
    ),
    [DialogIdent.SUBSCRIPTION_CANCEL]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/productSubscriptionCancel/productSubscriptionCancel.vue'
        ),
    ),
    [DialogIdent.SUBSCRIPTION_REACTIVATE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/productSubscriptionReactivate/productSubscriptionReactivate.vue'
        ),
    ),
    [DialogIdent.SUBSCRIPTION_ACTIVATE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/productSubscriptionActivate/productSubscriptionActivate.vue'
        ),
    ),
    [DialogIdent.NEW_EDIT_BANKDATA]: defineAsyncComponent(
      () =>
        import('~~/src/components/dialog/bankData/bankDataNewEditDialog.vue'),
    ),
    [DialogIdent.DELETE_BANKDATA]: defineAsyncComponent(
      () =>
        import('~~/src/components/dialog/bankData/bankDataDeleteDialog.vue'),
    ),
    [DialogIdent.FAV_LIST_MULTI_SELECT_DELETE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/favListMultiSelectDelete/multiSelectDelete.vue'
        ),
    ),
    [DialogIdent.FAV_LIST_MULTI_SELECT_MOVE_ITEMS]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/favListMultiSelectMoveItems/multiSelectMoveItems.vue'
        ),
    ),
    [DialogIdent.DRUG_CONTACT_ADDRESS_CHOICE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/drugAddressChoice/drugAddressChoiceDialog.vue'
        ),
    ),
    [DialogIdent.EDIT_CAMPAIGN]: defineAsyncComponent(
      () =>
        import('~~/src/components/dialog/editCampaign/editCampaignDialog.vue'),
    ),
    [DialogIdent.RETURN_CLAIM_CHOICE]: defineAsyncComponent(
      () => import('~~/src/components/dialog/returnsClaim/returnsClaim.vue'),
    ),
    [DialogIdent.RETURN_ITEMS_EXCLUSION_REASON]: defineAsyncComponent(
      () =>
        import('~~/src/components/dialog/exclusionReason/exclusionReason.vue'),
    ),
    [DialogIdent.ACTION_PAGE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/actionPageDialog/actionPageDialog.vue'
        ),
    ),
    [DialogIdent.PRODUCT_REQUEST]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/productRequest/editContactDataProductRequest.vue'
        ),
    ),
    [DialogIdent.COMPLAINT_CONTACT_DATA]: defineAsyncComponent(
      () =>
        import(
          '~/components/dialog/complaintFormContactData/complaintFormContactDataDialog.vue'
        ),
    ),
    [DialogIdent.COLLECTIVE_INVOICE]: defineAsyncComponent(
      () =>
        import(
          '~/components/dialog/collectiveInvoiceDialog/collectiveInvoiceDialog.vue'
        ),
    ),
    [DialogIdent.VERIFICATION_FILE_UPLOAD_SUCCESS]: defineAsyncComponent(
      () =>
        import(
          '~/components/dialog/uploadVerificationFileSuccess/uploadVerificationFileSuccess.vue'
        ),
    ),
    [DialogIdent.LOADING]: defineAsyncComponent(
      () => import('~/components/dialog/loadingDialog/loadingDialog.vue'),
    ),
  },
  [SiteIdent.default]: {
    [DialogIdent.LOGIN]: defineAsyncComponent(
      () => import('~~/src/components/dialog/login/vdv/loginDialog.vue'),
    ),
    [DialogIdent.AUTO_LOGOUT]: defineAsyncComponent(
      () => import('~~/src/components/dialog/autologout/autoLogoutDialog.vue'),
    ),
    [DialogIdent.SEND_FORGOT_PASSWORD_EMAIL]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/sendForgotPassword/vdv/sendForgotPassword.vue'
        ),
    ),
    [DialogIdent.RESET_LEGACY_PASSWORD]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/resetLegacyPasswort/vdv/resetLegacyPasswort.vue'
        ),
    ),
    [DialogIdent.CHANGE_PASSWORD]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/changePassword/vdv/changePassword.vue'
        ),
    ),
    [DialogIdent.REGISTRATION]: defineAsyncComponent(
      () =>
        import('~~/src/components/dialog/registration/vdv/registration.vue'),
    ),
    [DialogIdent.EDIT_PROFILE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/editProfile/vdv/editProfileDialog.vue'
        ),
    ),
    [DialogIdent.NEW_EDIT_ADDRESS]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/newEditAddress/vdv/newEditAddress.vue'
        ),
    ),
    [DialogIdent.DELETE_ADDRESS]: defineAsyncComponent(
      () =>
        import('~~/src/components/dialog/deleteAddress/vdv/deleteAddress.vue'),
    ),
    [DialogIdent.UPLOAD_VERIFICATION_FILES]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/uploadVerificationFile/uploadVerificationFileDialog.vue'
        ),
    ),
    [DialogIdent.DELETE_VERIFICATION_FILE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/deleteVerificationFile/vdv/deleteVerificationFile.vue'
        ),
    ),
    [DialogIdent.ORDER_ALARM_STATUS]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/orderAlarm/vdv/editOrderAlarmStatus.vue'
        ),
    ),
    [DialogIdent.NEW_EDIT_FAVORITE_LIST]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/newEditFavoriteList/vdv/newEditFavoriteList.vue'
        ),
    ),
    [DialogIdent.SUBSCRIPTION_DELIVERY_NOW]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/productSubscriptionDeliveryNow/productSubscriptionDeliveryNow.vue'
        ),
    ),
    [DialogIdent.SUBSCRIPTION_PAUSE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/productSubscriptionPause/productSubscriptionPause.vue'
        ),
    ),
    [DialogIdent.SUBSCRIPTION_EDIT]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/productSubscriptionEdit/productSubscriptionEdit.vue'
        ),
    ),
    [DialogIdent.SUBSCRIPTION_CANCEL]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/productSubscriptionCancel/productSubscriptionCancel.vue'
        ),
    ),
    [DialogIdent.SUBSCRIPTION_REACTIVATE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/productSubscriptionReactivate/productSubscriptionReactivate.vue'
        ),
    ),
    [DialogIdent.SUBSCRIPTION_ACTIVATE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/productSubscriptionActivate/productSubscriptionActivate.vue'
        ),
    ),
    [DialogIdent.DELETE_FAVORITE_LIST]: defineAsyncComponent(
      () =>
        import('~~/src/components/dialog/deleteFavList/vdv/deleteFavList.vue'),
    ),
    [DialogIdent.NEW_EDIT_BANKDATA]: defineAsyncComponent(
      () =>
        import('~~/src/components/dialog/bankData/bankDataNewEditDialog.vue'),
    ),
    [DialogIdent.DELETE_BANKDATA]: defineAsyncComponent(
      () =>
        import('~~/src/components/dialog/bankData/bankDataDeleteDialog.vue'),
    ),
    [DialogIdent.FAV_LIST_MULTI_SELECT_DELETE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/favListMultiSelectDelete/multiSelectDelete.vue'
        ),
    ),
    [DialogIdent.FAV_LIST_MULTI_SELECT_MOVE_ITEMS]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/favListMultiSelectMoveItems/multiSelectMoveItems.vue'
        ),
    ),
    [DialogIdent.DRUG_CONTACT_ADDRESS_CHOICE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/drugAddressChoice/drugAddressChoiceDialog.vue'
        ),
    ),
    [DialogIdent.EDIT_CAMPAIGN]: defineAsyncComponent(
      () =>
        import('~~/src/components/dialog/editCampaign/editCampaignDialog.vue'),
    ),
    [DialogIdent.RETURN_CLAIM_CHOICE]: defineAsyncComponent(
      () => import('~~/src/components/dialog/returnsClaim/returnsClaim.vue'),
    ),
    [DialogIdent.RETURN_ITEMS_EXCLUSION_REASON]: defineAsyncComponent(
      () =>
        import('~~/src/components/dialog/exclusionReason/exclusionReason.vue'),
    ),
    [DialogIdent.ACTION_PAGE]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/actionPageDialog/actionPageDialog.vue'
        ),
    ),
    [DialogIdent.PRODUCT_REQUEST]: defineAsyncComponent(
      () =>
        import(
          '~~/src/components/dialog/productRequest/editContactDataProductRequest.vue'
        ),
    ),
    [DialogIdent.COMPLAINT_CONTACT_DATA]: defineAsyncComponent(
      () =>
        import(
          '~/components/dialog/complaintFormContactData/complaintFormContactDataDialog.vue'
        ),
    ),
    [DialogIdent.VERIFICATION_FILE_UPLOAD_SUCCESS]: defineAsyncComponent(
      () =>
        import(
          '~/components/dialog/uploadVerificationFileSuccess/uploadVerificationFileSuccess.vue'
        ),
    ),
    [DialogIdent.LOADING]: defineAsyncComponent(
      () => import('~/components/dialog/loadingDialog/loadingDialog.vue'),
    ),
  },
};

export function useDialogMap() {
  const siteIdent = useSiteIdent();
  return map[Object.keys(map).find((key) => siteIdent.startsWith(key))];
}
