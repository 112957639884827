/* eslint-disable */
// @ts-nocheck
export function initCmp(siteCmpId: string) {
  if (process.client) {
    var anyWindow = window as any;
    anyWindow.gdprAppliesGlobally = true;
    if (!('cmp_id' in anyWindow) || anyWindow.cmp_id < 1) {
      anyWindow.cmp_id = 0;
    }
    if (!('cmp_cdid' in anyWindow)) {
      anyWindow.cmp_cdid = siteCmpId;
    }
    if (!('cmp_params' in anyWindow)) {
      anyWindow.cmp_params = '';
    }
    if (!('cmp_host' in anyWindow)) {
      anyWindow.cmp_host = 'a.delivery.consentmanager.net';
    }
    if (!('cmp_cdn' in anyWindow)) {
      anyWindow.cmp_cdn = 'cdn.consentmanager.net';
    }
    if (!('cmp_proto' in anyWindow)) {
      anyWindow.cmp_proto = 'https:';
    }
    if (!('cmp_codesrc' in anyWindow)) {
      anyWindow.cmp_codesrc = '1';
    }
    anyWindow.cmp_getsupportedLangs = function () {
      var b = [
        'DE',
        'EN',
        'FR',
        'IT',
        'NO',
        'DA',
        'FI',
        'ES',
        'PT',
        'RO',
        'BG',
        'ET',
        'EL',
        'GA',
        'HR',
        'LV',
        'LT',
        'MT',
        'NL',
        'PL',
        'SV',
        'SK',
        'SL',
        'CS',
        'HU',
        'RU',
        'SR',
        'ZH',
        'TR',
        'UK',
        'AR',
        'BS',
      ];
      if ('cmp_customlanguages' in anyWindow) {
        for (var a = 0; a < anyWindow.cmp_customlanguages.length; a++) {
          b.push(anyWindow.cmp_customlanguages[a].l.toUpperCase());
        }
      }
      return b;
    };
    anyWindow.cmp_getRTLLangs = function () {
      var a = ['AR'];
      if ('cmp_customlanguages' in anyWindow) {
        for (var b = 0; b < anyWindow.cmp_customlanguages.length; b++) {
          if (
            'r' in anyWindow.cmp_customlanguages[b] &&
            anyWindow.cmp_customlanguages[b].r
          ) {
            a.push(anyWindow.cmp_customlanguages[b].l);
          }
        }
      }
      return a;
    };
    anyWindow.cmp_getlang = function (j) {
      if (typeof j != 'boolean') {
        j = true;
      }
      if (
        j &&
        typeof cmp_getlang.usedlang == 'string' &&
        cmp_getlang.usedlang !== ''
      ) {
        return cmp_getlang.usedlang;
      }
      var g = anyWindow.cmp_getsupportedLangs();
      var c = [];
      var f = location.hash;
      var e = location.search;
      var a = 'languages' in navigator ? navigator.languages : [];
      if (f.indexOf('cmplang=') != -1) {
        c.push(f.substr(f.indexOf('cmplang=') + 8, 2).toUpperCase());
      } else {
        if (e.indexOf('cmplang=') != -1) {
          c.push(e.substr(e.indexOf('cmplang=') + 8, 2).toUpperCase());
        } else {
          if ('cmp_setlang' in anyWindow && anyWindow.cmp_setlang != '') {
            c.push(anyWindow.cmp_setlang.toUpperCase());
          } else {
            if (a.length > 0) {
              for (var d = 0; d < a.length; d++) {
                c.push(a[d]);
              }
            }
          }
        }
      }
      if ('language' in navigator) {
        c.push(navigator.language);
      }
      if ('userLanguage' in navigator) {
        c.push(navigator.userLanguage);
      }
      var h = '';
      for (var d = 0; d < c.length; d++) {
        var b = c[d].toUpperCase();
        if (g.indexOf(b) != -1) {
          h = b;
          break;
        }
        if (b.indexOf('-') != -1) {
          b = b.substr(0, 2);
        }
        if (g.indexOf(b) != -1) {
          h = b;
          break;
        }
      }
      if (
        h == '' &&
        typeof cmp_getlang.defaultlang == 'string' &&
        cmp_getlang.defaultlang !== ''
      ) {
        return cmp_getlang.defaultlang;
      } else {
        if (h == '') {
          h = 'EN';
        }
      }
      h = h.toUpperCase();
      return h;
    };
    (function () {
      var n = document;
      var p = anyWindow;
      var f = '';
      var b = '_en';
      if ('cmp_getlang' in p) {
        f = p.cmp_getlang().toLowerCase();
        if ('cmp_customlanguages' in p) {
          for (var h = 0; h < p.cmp_customlanguages.length; h++) {
            if (p.cmp_customlanguages[h].l.toUpperCase() == f.toUpperCase()) {
              f = 'en';
              break;
            }
          }
        }
        b = '_' + f;
      }
      function g(e, d) {
        var l = '';
        e += '=';
        var i = e.length;
        if (location.hash.indexOf(e) != -1) {
          l = location.hash.substr(location.hash.indexOf(e) + i, 9999);
        } else {
          if (location.search.indexOf(e) != -1) {
            l = location.search.substr(location.search.indexOf(e) + i, 9999);
          } else {
            return d;
          }
        }
        if (l.indexOf('&') != -1) {
          l = l.substr(0, l.indexOf('&'));
        }
        return l;
      }
      var j = 'cmp_proto' in p ? p.cmp_proto : 'https:';
      if (j != 'http:' && j != 'https:') {
        j = 'https:';
      }
      var k = 'cmp_ref' in p ? p.cmp_ref : location.href;
      var q = n.createElement('script');
      q.setAttribute('data-cmp-ab', '1');
      var c = g('cmpdesign', '');
      var a = g('cmpregulationkey', '');
      var o = g('cmpatt', '');
      q.src =
        j +
        '//' +
        p.cmp_host +
        '/delivery/cmp.php?' +
        ('cmp_id' in p && p.cmp_id > 0 ? 'id=' + p.cmp_id : '') +
        ('cmp_cdid' in p ? 'cdid=' + p.cmp_cdid : '') +
        '&h=' +
        encodeURIComponent(k) +
        (c != '' ? '&cmpdesign=' + encodeURIComponent(c) : '') +
        (a != '' ? '&cmpregulationkey=' + encodeURIComponent(a) : '') +
        (o != '' ? '&cmatt=' + encodeURIComponent(o) : '') +
        ('cmp_params' in p ? '&' + p.cmp_params : '') +
        (n.cookie.length > 0 ? '&__cmpfcc=1' : '') +
        '&l=' +
        f.toLowerCase() +
        '&o=' +
        new Date().getTime();
      q.type = 'text/javascript';
      q.async = true;
      if (n.currentScript && n.currentScript.parentElement) {
        n.currentScript.parentElement.appendChild(q);
      } else {
        if (n.body) {
          n.body.appendChild(q);
        } else {
          var m = n.getElementsByTagName('body');
          if (m.length == 0) {
            m = n.getElementsByTagName('div');
          }
          if (m.length == 0) {
            m = n.getElementsByTagName('span');
          }
          if (m.length == 0) {
            m = n.getElementsByTagName('ins');
          }
          if (m.length == 0) {
            m = n.getElementsByTagName('script');
          }
          if (m.length == 0) {
            m = n.getElementsByTagName('head');
          }
          if (m.length > 0) {
            m[0].appendChild(q);
          }
        }
      }
      var q = n.createElement('script');
      q.src = j + '//' + p.cmp_cdn + '/delivery/js/cmp' + b + '.min.js';
      q.type = 'text/javascript';
      q.setAttribute('data-cmp-ab', '1');
      q.async = true;
      if (n.currentScript && n.currentScript.parentElement) {
        n.currentScript.parentElement.appendChild(q);
      } else {
        if (n.body) {
          n.body.appendChild(q);
        } else {
          var m = n.getElementsByTagName('body');
          if (m.length == 0) {
            m = n.getElementsByTagName('div');
          }
          if (m.length == 0) {
            m = n.getElementsByTagName('span');
          }
          if (m.length == 0) {
            m = n.getElementsByTagName('ins');
          }
          if (m.length == 0) {
            m = n.getElementsByTagName('script');
          }
          if (m.length == 0) {
            m = n.getElementsByTagName('head');
          }
          if (m.length > 0) {
            m[0].appendChild(q);
          }
        }
      }
    })();
    anyWindow.cmp_addFrame = function (b) {
      if (!anyWindow.frames[b]) {
        if (document.body) {
          var a = document.createElement('iframe');
          a.style.cssText = 'display:none';
          if (
            'cmp_cdn' in anyWindow &&
            'cmp_ultrablocking' in anyWindow &&
            anyWindow.cmp_ultrablocking > 0
          ) {
            a.src = '//' + anyWindow.cmp_cdn + '/delivery/empty.html';
          }
          a.name = b;
          document.body.appendChild(a);
        } else {
          anyWindow.setTimeout(anyWindow.cmp_addFrame, 10, b);
        }
      }
    };
    anyWindow.cmp_rc = function (h) {
      var b = document.cookie;
      var f = '';
      var d = 0;
      while (b != '' && d < 100) {
        d++;
        while (b.substr(0, 1) == ' ') {
          b = b.substr(1, b.length);
        }
        var g = b.substring(0, b.indexOf('='));
        if (b.indexOf(';') != -1) {
          var c = b.substring(b.indexOf('=') + 1, b.indexOf(';'));
        } else {
          var c = b.substr(b.indexOf('=') + 1, b.length);
        }
        if (h == g) {
          f = c;
        }
        var e = b.indexOf(';') + 1;
        if (e == 0) {
          e = b.length;
        }
        b = b.substring(e, b.length);
      }
      return f;
    };
    anyWindow.cmp_stub = function () {
      var a = arguments;
      __cmp.a = __cmp.a || [];
      if (!a.length) {
        return __cmp.a;
      } else {
        if (a[0] === 'ping') {
          if (a[1] === 2) {
            a[2](
              {
                gdprApplies: gdprAppliesGlobally,
                cmpLoaded: false,
                cmpStatus: 'stub',
                displayStatus: 'hidden',
                apiVersion: '2.0',
                cmpId: 31,
              },
              true,
            );
          } else {
            a[2](false, true);
          }
        } else {
          if (a[0] === 'getUSPData') {
            a[2]({ version: 1, uspString: anyWindow.cmp_rc('') }, true);
          } else {
            if (a[0] === 'getTCData') {
              __cmp.a.push([].slice.apply(a));
            } else {
              if (
                a[0] === 'addEventListener' ||
                a[0] === 'removeEventListener'
              ) {
                __cmp.a.push([].slice.apply(a));
              } else {
                if (a.length == 4 && a[3] === false) {
                  a[2]({}, false);
                } else {
                  __cmp.a.push([].slice.apply(a));
                }
              }
            }
          }
        }
      }
    };
    anyWindow.cmp_msghandler = function (d) {
      var a = typeof d.data === 'string';
      try {
        var c = a ? JSON.parse(d.data) : d.data;
      } catch (f) {
        var c = null;
      }
      if (typeof c === 'object' && c !== null && '__cmpCall' in c) {
        var b = c.__cmpCall;
        anyWindow.__cmp(b.command, b.parameter, function (h, g) {
          var e = {
            __cmpReturn: { returnValue: h, success: g, callId: b.callId },
          };
          d.source.postMessage(a ? JSON.stringify(e) : e, '*');
        });
      }
      if (typeof c === 'object' && c !== null && '__uspapiCall' in c) {
        var b = c.__uspapiCall;
        anyWindow.__uspapi(b.command, b.version, function (h, g) {
          var e = {
            __uspapiReturn: { returnValue: h, success: g, callId: b.callId },
          };
          d.source.postMessage(a ? JSON.stringify(e) : e, '*');
        });
      }
      if (typeof c === 'object' && c !== null && '__tcfapiCall' in c) {
        var b = c.__tcfapiCall;
        anyWindow.__tcfapi(
          b.command,
          b.version,
          function (h, g) {
            var e = {
              __tcfapiReturn: { returnValue: h, success: g, callId: b.callId },
            };
            d.source.postMessage(a ? JSON.stringify(e) : e, '*');
          },
          b.parameter,
        );
      }
    };
    anyWindow.cmp_setStub = function (a) {
      if (
        !(a in anyWindow) ||
        (typeof anyWindow[a] !== 'function' &&
          typeof anyWindow[a] !== 'object' &&
          (typeof anyWindow[a] === 'undefined' || anyWindow[a] !== null))
      ) {
        anyWindow[a] = anyWindow.cmp_stub;
        anyWindow[a].msgHandler = anyWindow.cmp_msghandler;
        anyWindow.addEventListener('message', anyWindow.cmp_msghandler, false);
      }
    };
    anyWindow.cmp_addFrame('__cmpLocator');
    if (!('cmp_disableusp' in anyWindow) || !anyWindow.cmp_disableusp) {
      anyWindow.cmp_addFrame('__uspapiLocator');
    }
    if (!('cmp_disabletcf' in anyWindow) || !anyWindow.cmp_disabletcf) {
      anyWindow.cmp_addFrame('__tcfapiLocator');
    }
    anyWindow.cmp_setStub('__cmp');
    if (!('cmp_disabletcf' in anyWindow) || !anyWindow.cmp_disabletcf) {
      anyWindow.cmp_setStub('__tcfapi');
    }
    if (!('cmp_disableusp' in anyWindow) || !anyWindow.cmp_disableusp) {
      anyWindow.cmp_setStub('__uspapi');
    }
  }
}
