/**
 * All Stores should import this file and use the StoreIds enum to define their store id.
 */

export enum StoreIds {
  AREA_CONFIG = 'area-config',
  CMS_PAGE = 'cms-page',
  RESERVATION = 'reservation',
  NAVIGATION = 'navigation',
  USER_CONTEXT = 'user-context',
  USER_ADDRESS = 'user-address',
  REDIRECT_DATA = 'redirect-data-passing',
  DIALOG = 'dialog',
  CART = 'cart',
  PREVENT_SCROLL = 'prevent-scroll',
  FAV_LISTS = 'fav-lists',
  TOAST = 'toast',
  CHECKOUT = 'checkout',
  RETURN_CHECKOUT = 'returns-checkout',
  SYSTEM_CONTEXT = 'system-context',
  SESSION = 'session',
  BANNER = 'banner',
}

export default StoreIds;
