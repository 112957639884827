import type { Router, RouteRecord } from 'vue-router';
import getSiteByHost from '@/utils/getSiteByHost';

export const shopPagesPatterns = [
  /.*\/shop\/.*/,
  /.*\/register.*/,
  /.*\/login.*/,
  /.*\/account.*/,
  /.*\/productlists.*/,
  /.*\/account\/.*/,
  /^\/checkout\/.*/,
  /.*\/complaint\/.*/,
  /.*\/productlists\/.*/,
  /.*\/banner\/.*/,
  /.*\/sales\/.*/,
];

export default defineNuxtPlugin(async (nuxtApp) => {
  const router = nuxtApp.$router as Router;

  const hostname = nuxtApp.ssrContext
    ? nuxtApp.ssrContext.event.node.req.headers.host
    : location.host;

  const { subdomain, languageCode, localeCode, siteIdent, siteId } =
    getSiteByHost(hostname);

  //get all routes
  const routes: RouteRecord[] = router.getRoutes();

  //remove all routes from router
  routes.forEach((route) => {
    router.removeRoute(route.name);
  });

  //filter routes so only routes from the subdomain and language remain
  let filteredRoutes = routes.filter(({ name }) =>
    (name as string).startsWith(subdomain),
  );

  if (!useShopEnabled()) {
    filteredRoutes = filteredRoutes.filter(
      ({ path }) => !shopPagesPatterns.some((regex) => regex.test(path)),
    );
  }

  // remove the subdomain and language from the path
  filteredRoutes.map((cur) => {
    cur.path = cur.path.replace(`/${subdomain}`, '') || '/';
    return cur;
  });

  //add the filtered routes to the router
  filteredRoutes.forEach((route) => {
    route.meta.layout = useInitialLayout(route.path, subdomain) as any;
    router.addRoute(route);
  });
  if (import.meta.server) {
    //refresh the current route
    await router.replace(router.currentRoute.value.fullPath);
  }

  return {
    provide: {
      subdomain,
      languageCode,
      localeCode,
      siteIdent,
      siteId,
    },
  };
});
