import type { BannerPositions } from '~/@types/bannerPositions';
import StoreIds from './storeIds';
import { useCachedGet } from '~/composables/dataFetching/genericFetchers';
import type { BannerAssignment } from '~/server/transformers/banner/transformer/assignments/bannerAssignmentTransformer';
import type { Result } from '~/server/api/[site]/banner/assignment/[position].get';

interface State {
  bannerAssignments: Record<BannerPositions, BannerAssignment[]>;
  positionsLoaded: BannerPositions[];
}

export const useBannerStore = defineStore(StoreIds.BANNER, {
  state: () =>
    ({
      bannerAssignments: {},
      positionsLoaded: [],
    }) as State,
  actions: {
    registerAssignments(
      assignments: BannerAssignment[] = [],
      includedPositions: BannerPositions[] = [],
    ) {
      this.positionsLoaded.push(...includedPositions);
      const mappedAssignments = assignments.reduce(
        (acc, assignment) => {
          if (!acc[assignment.position]) {
            acc[assignment.position] = [];
          }

          acc[assignment.position].push(assignment);
          return acc;
        },
        {} as Record<BannerPositions, BannerAssignment[]>,
      );
      Object.assign(this.bannerAssignments, mappedAssignments);
    },
    async getBannerAssignments(
      position: BannerPositions,
    ): Promise<BannerAssignment[]> {
      if (!this.positionsLoaded.includes(position)) {
        const site = useSiteIdent();

        try {
          const assignments = await useCachedGet<Result>(
            `/api/${site}/banner/assignment/${position}`,
          );

          if (assignments.data?.value?.length) {
            this.registerAssignments(assignments.data.value, [position]);
          }
        } catch (e) {
          handleLoadingError(e);
        }
      }
      return this.bannerAssignments[position] ?? [];
    },
  },
});
