export const hashCode = (item: any): number => {
  return Math.abs(
    JSON.stringify(item)
      .split('')
      .reduce(
        (prevHash, currVal) =>
          ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
        0,
      ),
  );
};
