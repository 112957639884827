/**
 * @returns Map<string, { mailChimpId: string; creationDate: Date }>
 * - Map-key => cart-token
 */
export default function useMailchimpMarketing() {
  return useLocalStorage(
    'mailchimp_campaign_id',
    new Map<string, { mailchimp_campaign_id: string; creationDate: Date }>(),
  );
}
