import { DialogIdent, useDialogStore } from '@/stores/useDialogStore';

export default function useLoadingDialog() {
  const dialogStore = useDialogStore();
  return {
    open: async (loadingText = '') => {
      return dialogStore.openDialog(DialogIdent.LOADING, { loadingText });
    },
    getData: () => {
      return dialogStore.getDialogData(DialogIdent.LOADING);
    },
    close: (data?: any) => {
      dialogStore.closeDialog(data);
    },
  };
}
