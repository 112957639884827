export enum LineItemType {
  product = 'product',
  /** Incomplete Products might miss many fields - some might be filled with BC data */
  incompleteProduct = 'incompleteProduct',
  subscription = 'subscription',
  incompleteSubscription = 'incompleteSubscription',
  surcharge = 'surcharge',
  promotion = 'promotion',
  campaign = 'campaign',
  /** Might only have _children, id, discount,_
   * but no further campaign data
   * */
  incompleteCampaign = 'incompleteCampaign',
  absoluteVoucher = 'absoluteVoucher',
  delivery = 'deliveryItem',
  text = 'text',
  account = 'account',
}
